@import (reference) './imports/global';

.aui-layer {
    #aui.text-reset();
    position: fixed;

    &:not([open]),
    &[hidden] {
        display: none;
    }

    #aui.focus-visible(#aui.with-focus-ring());
}



.aui-blanket {
    #aui.transition.fadeOut(@aui-blanket-fade-time, @aui-blanket-fade-delay);

    background: @aui-blanket-bg-color;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: @aui-z-blanket;

    &:not([hidden]) {
        #aui.transition.fadeIn(@aui-blanket-fade-time, @aui-blanket-fade-delay);
    }
}
