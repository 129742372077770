@import (reference) './imports/global';
@import (reference) './imports/mixins/shadows';
@import (reference) './imports/aui-theme/theme';


/*! AUI Date Picker, using jQuery UI Date Picker */
/*
 * CSS is a bit curious because it has to work both with and without jQuery UI's theme CSS present.
 * jQuery UI themes are not meant to be loaded, but we can't prevent the scenario.
 */

@aui-datepicker-outer-gutter: 5px;
@aui-datepicker-option-width: 35px;

.aui-datepicker-dialog {
    max-width: (@aui-datepicker-option-width * 7) + (@aui-datepicker-outer-gutter * 2);
}

.aui-datepicker-dialog .aui-datepicker-hint {
    background: var(--aui-datepicker-panel-bg-color);
    border-bottom: 1px solid var(--aui-datepicker-panel-divider-color);
    color: var(--aui-datepicker-hint-text-color);
    font-size: @aui-font-size-medium;
    padding: 2px 5px;
    border-radius: @aui-border-radius-smallish;
    #aui.shadow.z200();
}

.aui-datepicker-dialog[x-placement*="bottom"]{
    #aui-themes.when-light({
        --aui-inline-dialog-bg-color: var(--aui-datepicker-heading-bg-color);
    });
}

.aui-datepicker-dialog .aui-inline-dialog-contents,
.aui-datepicker-dialog .contents {
    padding: 0;
}
.aui-datepicker-dialog .aui-inline-dialog-arrow.aui-css-arrow:not(.aui-bottom-arrow):after,
.aui-datepicker-dialog .arrow.aui-css-arrow:not(.aui-bottom-arrow):after {
    border-bottom-color: var(--aui-datepicker-panel-bg-color);
    top: 2px; //AUI-2522: fix for top arrow in FF
}

.aui-datepicker-dialog .ui-datepicker {
    background: inherit;
    color: inherit;
    font-size: @aui-font-size-medium;
    font-family: @aui-font-family;
    padding: 0;
}

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-header {
    background: var(--aui-datepicker-heading-bg-color);
    height: 14px;
    margin: 0;
    padding: 10px;
    position:relative;
}

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-title {
    color: var(--aui-datepicker-heading-text-color);
    font-size: @aui-font-size-medium;
    font-weight: bold;
    height: 14px;
    line-height: 14px;
    margin: 0;
    padding: 0;
    text-align: center;
}

/* jQuery UI Date Picker - Calendar */

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar {
    border-spacing: 0;
    margin: 0;
    table-layout: fixed;
    width: 100%;
}

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar thead {
    background: var(--aui-datepicker-heading-bg-color);
}

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar tbody {
    background: var(--aui-datepicker-panel-bg-color);
    padding-bottom: 5px;
}

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar th {
    line-height: 15px;
}

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar th {
    #aui.typography.h100(var(--aui-datepicker-heading-weekdays-text-color));
}

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar th,
.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar td {
    cursor: default; // so that it doesn't flip between the text-selection and pointer cursors
    padding: 0;
    width: @aui-datepicker-option-width;
}

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar td a,
.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar td span {
    border: 1px solid transparent;
    border-radius: @aui-border-radius-smallish;
    box-sizing: border-box;
    display: block;
    font-size: @aui-font-size-medium;
    font-family: @aui-font-family;
    line-height: 25px;
    padding: 0;
    text-align: center;
}

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar td a {
    background: var(--aui-datepicker-option-bg-color);
    color: var(--aui-datepicker-option-text-color);
    text-decoration: none;
    text-align: center;
}

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar td a:hover,
.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar td a:active,
.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar td a:focus {
    background: var(--aui-datepicker-option-focus-bg-color);
    border-color: $background;
    color: var(--aui-datepicker-option-focus-text-color);
}

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-current-day a {
    background: var(--aui-datepicker-option-selected-bg-color);
    border-color: $background;
    color: var(--aui-datepicker-option-selected-text-color);
}

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-current-day a:hover,
.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-current-day a:focus {
    background: var(--aui-datepicker-option-selected-bg-color);
    border-color: $background;
}

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-today {
    font-weight: @aui-font-weight-bold;
    text-decoration: underline;
    text-decoration-width: 2px;
}

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-unselectable {
    background-color: var(--aui-datepicker-option-unselectable-bg-color);
    color: var(--aui-datepicker-option-unselectable-text-color);
}

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-other-month.ui-datepicker-unselectable {
    background-color: var(--aui-datepicker-option-bg-color);
}

/* jQuery UI Overrides (default theme) */

.aui-datepicker-dialog .ui-widget-header {
    font-weight: normal;
}

.aui-datepicker-dialog .ui-widget-header,
.aui-datepicker-dialog .ui-widget-content {
    border: none;
}

.aui-datepicker-dialog .ui-datepicker td a,
.aui-datepicker-dialog .ui-datepicker td span {
    &,
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-prev,
.aui-datepicker-dialog .ui-datepicker .ui-datepicker-prev-hover,
.aui-datepicker-dialog .ui-datepicker .ui-datepicker-next,
.aui-datepicker-dialog .ui-datepicker .ui-datepicker-next-hover {
    cursor: default;
    height: 100%;
    line-height: 35px;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 40px;
    border-width: 0;

    span {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
        margin: 0;
        width: 100%;
        text-indent: 0;
    }
}

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-prev,
.aui-datepicker-dialog .ui-datepicker .ui-datepicker-prev-hover { left: 0; }
.aui-datepicker-dialog .ui-datepicker .ui-datepicker-next,
.aui-datepicker-dialog .ui-datepicker .ui-datepicker-next-hover { right: 0; }

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
.aui-datepicker-dialog .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
    &.ui-state-disabled {
        &,
        &:hover,
        &:active,
        &:focus {
            color: var(--aui-datepicker-disabled-text-color);
        }
    }
}

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar > thead > tr > th {
    padding-bottom: 5px;
    border-bottom: 1px solid var(--aui-datepicker-panel-divider-color);
}

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar > thead > tr > th:first-child,
.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar > tbody > tr > td:first-child {
    padding-left: 5px;
}

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar > thead > tr > th:last-child,
.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar > tbody > tr > td:last-child  {
    padding-right: 5px;
}

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar > tbody > tr:first-child > td {
    padding-top: 5px;
}

.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar > tbody > tr:last-child > td {
    padding-bottom: 5px;
}

.aui-calendar-widget {
    display: inline-flex;
    flex-direction: column;
}
