@import (reference) './imports/global';
@import (reference) './imports/aui-theme/components/forms';
/*
    Icons.
*/
//Order is important for display preference
[data-aui-notification-info]+.aui-icon.aui-icon-notification {
    &:before {
        content: @aui-notification-info-icon;
    }
    color: @aui-notification-info-color;
}

[data-aui-notification-success]+.aui-icon.aui-icon-notification {
    &:before {
        content: @aui-notification-success-icon;
    }
    color: @aui-notification-success-color;
}

[data-aui-notification-error]+.aui-icon.aui-icon-notification {
    &:before {
        content: @aui-notification-error-icon;
    }
    color: @aui-notification-error-color;
}

[data-aui-notification-field].aui-form-notification-field-no-icon+.aui-icon.aui-icon-notification {
    visibility: hidden;
}

//By default icons are outside of fields
.aui-icon-notification {
    vertical-align: text-bottom;
}

//Borders: Components that should have coloured borders
form.aui {
    .password,
    .text,
    .textarea,
    .select {
        &[data-aui-notification-error] {
            border-color: @aui-notification-error-color;
        }
        &[data-aui-notification-success] {
            border-color: @aui-notification-success-color;
        }
    }
}

form.aui.aui-legacy-forms {
    .aui-form-error-border() {
        border: @aui-form-button-focus-border-width solid @aui-notification-error-color;
    }
    [data-aui-notification-error] {
        .radio.radio {
            & {
                input:checked:focus ~ label:not(:active)::after {
                    content: ' ';
                    .aui-form-error-border();
                }
                input:checked:not(focus) ~ label:not(:active)::after {
                    content: ' ';
                    .aui-form-error-border();
                    border-radius: @aui-form-button-focus-border-width * 4;
                    position: absolute;
                    top: 2px;
                    left: 0;
                    #aui-forms.aui-radio-checkbox-size();
                }
                input:not(:checked) ~ label:not(:active)::before {
                    .aui-form-error-border();
                }
            }
        }
        .checkbox.checkbox {
            & label::before {
                .aui-form-error-border();
            }
        }
    }
}

form.aui {
   input[data-aui-validation-field] ~ aui-spinner {
        margin-left: 0;
        margin-top: 0;
        position: relative;
        right: (@aui-notification-icon-outside-field-width + @aui-notification-icon-outside-field-padding-right);
        top: @aui-notification-icon-outside-field-padding-top;
        vertical-align: top;
        display: inline-block;
    }
}
