@import (reference) './imports/global';

/**
* Basic icon styles. Allows you to easily render sprite icons.
*
* .aui-icon must be present on all iconified elements
* All iconified elements must include class names prefixed with 'aui-icon-'
* Icons assume light backgrounds. Icon classnames for dark backgrounds must be suffixed with '-d'
* For elements whose background changes in app, we advise maintaining light/dark state in the app.
*
*/

.aui-icon {
    --aui-icon-size: @aui-icon-size-small;
    --aui-icon-font: @aui-icon-font-family;

    background-repeat: no-repeat;
    background-position: 0 0;
    border: none;
    display: inline-flex;
    font-size: 0;
    height: var(--aui-icon-size);
    line-height: 0;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: left;
    vertical-align: text-bottom;
    width: var(--aui-icon-size);

    &::before {
        #aui.icon-pseudoelement(var(--aui-icon-font));
        color: inherit;
        content: var(--aui-ig, unset);
        font-size: var(--aui-icon-size);
        line-height: 1;
        margin: auto; // aligns in the middle of the flexed element.
        text-indent: 0;
    }
}

.aui-icon-small {
    --aui-icon-size: @aui-icon-size-small;
}

.aui-icon-large {
    --aui-icon-size: @aui-icon-size-large;
}

/* Form icons - deprecated */
form.aui .icon-date,
.aui-icon-date {
    /* icon-date.png */
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAIAAACQkWg2AAAARElEQVR4AWMoIBGANFgXr8dE927fw0TkaCDLSf/B4O7duwQZ5Gq4iwNccDGHI4gIARuA6tAYJGig1En0DyVMQFcnkQgA7103vYurRvEAAAAASUVORK5CYII=');
}
form.aui .icon-range,
.aui-icon-range {
    /* icon-range.png */
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAIAAACQkWg2AAAARklEQVR4AWMoIBGANFgXr8dEd7EBcjSQ5aT/YAA0gCCDXA0Q911wMSeIUGwA8gkyyNWAsJRyJ6F5mmQNhJ2EFtN0ijgSAQD0WQfB7IGD0QAAAABJRU5ErkJggg==');
}

.aui-icon-required {
    /* icon-required.png */
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAARUlEQVR4AWOAgQsu5v8ZSAIIjaVAbA4yAEqXkmpAMxDfABkApZtJNYARiHtABkBpRlIN0AbiK0AcBMQrQHyGkQdGwSgAAPtCJnBUV/TSAAAAAElFTkSuQmCC');
}

form.aui .icon-users,
.aui-icon-users {
    /* icon-users.png */
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAArElEQVR4AWOgCbAuXi8HxLuA+DOUliPVgN1A/B8J78epuKCgwBOIn0GxJ9SAz2gG/MSllgHK+Q/Fz/C5AFMtpuBzWBhADfkCpeWxqoUKeoE4QPwExMbhU9LUAm2UAuK9IL8D8R4glmQgYLIGCCMZsBYtDNZiqkUIlCH5qxxqwCc0Az5jVQsVfI4k+A5qwH90jEstA4SDwLgNwFQ7jAy4gSR4F2rAaTQDjuFSCwBFeGK4drVwMAAAAABJRU5ErkJggg==');
}
form.aui .icon-help,
.aui-icon-help,
form.aui .icon-inline-help,
.aui-icon-inline-help {
    /* icon-help.png */
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAABG0lEQVR4AU2RNVYtQRiE+7m7IxHRlRxnHWwAty9lDbg7K4AUh7kJ7vDwEGLcvU6f6cP8NVJVXe3GFWGqWedSWBcLm2DxjmZuuSFGgxATu9X/tWt+zTAPdBNnnBNHl5whP0KDRJm1ezjimn6SpArlNhqRCLd02eA0h+SSzS47PJfuUEvEUMMN/yR/8UC5DVaKJej/T6PVGNbxjC1SeKtvBgfMawQVHhuGS9oC+8ninGW3XNq4MlwRDAyzx1enFDgxbOAFAhP0BpTHf7dIZyW64f1F1hqi3NLhW584Ej77StskKkIjD5Ra6y0bwlvLC7inyR31iCJd2MF5Zs+0U84wr58uq5U7bvBoEDxupBrVHCwi1LLJNaf8F4s4/xGiuqDPs2tUOAAAAABJRU5ErkJggg==');
}
