@import (reference) './imports/global';

.aui-expander-short-content[hidden],
.aui-expander-content[hidden] {
    display: none;
}

.aui-expander-content {
    height: 0;
    overflow: hidden;
    position: relative;
}

/**
* ! Deprecated since 9.9.0
* delete at version 10.0.0
* aria-expanded will no longer be used as an
* determinant if the expander is expanded
*/
.aui-expander-content[aria-expanded="true"],
.aui-expander-content.expanded {
    height: auto;
}

.aui-expander-trigger {
    cursor: pointer;
    color: var(--aui-link-color);
    padding: 0;
    background-color: inherit;
    border: none;
    text-decoration: underline;
}

/* ADG REVEAL TEXT PATTERN */
.aui-expander-trigger.aui-expander-reveal-text {
    background: @aui-panel-bg-color;
    bottom: 0;
    position: absolute;
    right: 0;
    padding-left: 10px;
    padding-block: 2px;

    &:before {
        color: @aui-text-color;
        content: "\2026\00a0"; /* ellipsis */
    }
}

.aui-expander-trigger.aui-expander-reveal-text[aria-expanded="true"] {
    position: relative;

    &::before {
        display: none;
    }
}
