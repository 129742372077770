@import (reference) './imports/global';
@import (reference) './imports/mixins/focus';
@import (reference) './imports/aui-theme/components/forms';
@import 'forms-legacy';
@import 'forms-current';

form.aui {
    margin: 20px 0 0 0;
}

form.aui:first-child {
    margin-top: 0;
}

/* Do not give file inputs a height or width because they break horribly in certain browsers. */
form.aui .upfile {
    box-sizing: border-box;
    font-family: inherit;
    font-size: inherit;
    margin: 5px 0;
    padding: 0;
}

form.aui optgroup,
form.aui option,
form.aui optgroup option {
    font-style: normal;
    font-weight: normal;
}

form.aui .text[disabled],
form.aui .password[disabled],
form.aui .textarea[disabled],
form.aui .select[disabled],
form.aui .multi-select[disabled],
form.aui .radio[disabled],
form.aui .checkbox[disabled] {
    cursor: not-allowed;
}

// Fixing Safari search fields (https://ecosystem.atlassian.net/browse/AUI-4062)
form.aui .text[type=search] {
    -webkit-appearance: textfield;
    outline-width: 5px;
    outline-offset: -2px;

    &::-webkit-search-decoration {
        -webkit-appearance: none;
    }
}

/* Structural elements */
form.aui fieldset {
    border: 0;
    clear: both;
    display: block;
    margin: 0;
    padding: 0;
    position: relative;
}

form.aui .field-value {
    display: inline-block;
    font-weight: bold;
    padding-top: 5px;
}

/* TODO: don't support this in AUI Forms 2 */
form.aui legend {
    display: none;
}

form.aui .group {
    padding-top: 0;
}

form.aui .field-group:before,
form.aui .field-group:after,
form.aui .group:before,
form.aui .group:after,
form.aui .date-select:before,
form.aui .date-select:after {
    content: " ";
    display: table;
}

form.aui .field-group:after,
form.aui .group:after,
form.aui .date-select:after {
    clear: both;
}

form.aui .checkbox > label,
form.aui .radio > label {
    font-size: @aui-font-size-medium;
    color: @aui-text-color;
    font-weight: @aui-font-weight-normal;
}

form.aui .checkbox > .checkbox[disabled] + label,
form.aui .radio > .radio[disabled] + label {
    color: @aui-form-disabled-field-label-color;
    cursor: not-allowed;
}

form.aui .field-group .error,
form.aui .group .error,
form.aui .checkbox .error,
form.aui .radio .error,
form.aui .error {
    clear: both;
    color: @aui-form-error-text-color;
    display: block;
    margin: 5px 0 0 0;

    .aui-iconfont-error {
        margin-right: 4px;
    }
}

form.aui .error {
    ul {
        padding: 0;
        list-style: none;
    }
}

form.aui .field-group .error:first-child,
form.aui .checkbox .error:first-child,
form.aui .radio .error:first-child {
    margin-top: 0;
}

form.aui .group legend,
form.aui .date-select legend {
    display: block;
}

form.aui .date-select .field-group label {
    display: none;
}

form.aui .matrix {
    padding-top: 5px;
}

/* Buttons */
form.aui .buttons-container {
    box-sizing: border-box;
    clear: both;
    margin: 1px 0 0 0;
    padding: 4px 0 4px 145px;
    position: relative;
    width: 100%;
}

/* Long Labels
 * add long-label class to form eg: <form class="aui long-label">
 */
form.aui.long-label .field-group,
form.aui.long-label .group,
form.aui.long-label .date-select,
form.aui.long-label .buttons-container {
    padding-left: 250px;
}

form.aui.long-label .field-group > label,
form.aui.long-label .field-group > aui-label,
form.aui.long-label .group > legend {
    margin-left: -250px;
    width: 235px;
}

form.aui.long-label .group .field-group,
form.aui.long-label .date-select .field-group {
    padding-left: 0;
}

/* End Long Labels */


/* Top Labels
 * add top-label class to form eg: <form class="aui top-label">
 * display all forms with top-labels for screen sizes < 768px
 */
.top-labels() {
    .field-group {
        padding-left: 0;
    }

    .field-group > label,
    .field-group > aui-label {
        font-size: @aui-font-size-small;
        font-weight: @aui-font-weight-semibold;
        display: block;
        float: none;
        margin: 0 0 (@aui-grid / 2) 0;
        padding: 0;
        text-align: left;
        width: auto;
    }

    .icon-required {
        left: 0;
        position: relative;
        top: 0;
    }

    .group,
    .date-select {
        padding-left: 0;
    }

    .group legend,
    .date-select legend {
        float: none;
        margin: 0;
        text-align: left;
        width: auto;
    }

    .date-select label {
        display: none;
    }

    .buttons-container {
        padding-left: 0;
    }

}

form.aui.top-label {
    .top-labels();
}

/* End Top Labels */

/* Deprecated button styling - use the new aui-button component */

/* Basic Button Style */
form.aui .button {
    #aui.box-sizing();
    #aui-buttons.aui-button-base();
    #aui-buttons.aui-button-style(normal);
    height: @aui-form-field-height;
    line-height: 1.4285714285714;
    margin: 0;
    padding: @aui-button-padding-y @aui-button-padding-x;
    text-decoration: none;
    vertical-align: baseline;
    white-space: nowrap;

    &:focus,
    &:hover {
        #aui-buttons.aui-button-style(hover);
    }

    &:active,
    &.active {
        #aui-buttons.aui-button-style(active);
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
        #aui-buttons.aui-button-style(disabled);
    }
}

form.aui .cancel {
    border-radius: @aui-button-border-radius;
    border: 0;
    cursor: pointer;
    font-size: @aui-button-font-size;
    display: inline-block;
    padding: 5px 10px;
    vertical-align: baseline;
}

/* Create 10px effective gap; backwards compat for old and new buttons */
/* zero font size hack to stop 4px magic number problems. */
form.aui .buttons-container > .buttons {
    font-size: 0;
}

/* ensure zero font size hack doesn't kill other elements. */
form.aui .buttons-container > .buttons > * {
    font-size: @aui-button-font-size;
}

form.aui .buttons-container .button + .button,
form.aui .buttons-container .button + .aui-button,
form.aui .buttons-container .aui-button + .button,
form.aui .buttons-container .aui-button + .aui-button {
    margin-left: 10px;
}

form.aui .buttons-container .aui-button + .aui-button-link {
    margin-left: 9px; /* 1px diff due to border */
}

/* Message Interops */
form.aui .aui-message + .field-group,
form.aui .aui-message + .group,
form.aui .aui-message + .date-select {
    margin-top: 10px;
}

/* Deprecated */
form.aui span.content {
    left: -9999px;
    position: absolute;
    top: -9999px;
}

form.aui pre.aui-form {
    @size: 12px;
    background-color: @aui-form-pre-bg-color;
    border: 1px solid @aui-border-color;
    font-family: @aui-code-font-family;
    font-size: @size;
    line-height: unit((20 / @size));
    overflow-x: auto;
    overflow-y: visible;
    padding: @aui-grid * 1.5;
}

// Responsive Styles

#aui.responsive-small({ form.aui, form.aui.long-label {
    .top-labels();
} });

form.aui:not(.aui-legacy-forms),
form.aui.aui-legacy-forms {
    /* Field widths
    * - don't add widths to file inputs - they break horribly
    */
    .text,
    .password,
    .textarea,
    .select,
    .multi-select {
        width: 100%;
        max-width: @aui-form-field-width-default;
    }

    .short-field {
        max-width: @aui-form-field-width-short;
    }

    .medium-field {
        max-width: @aui-form-field-width-medium;
    }

    .medium-long-field {
        max-width: @aui-form-field-width-medium-long;
    }

    .long-field {
        max-width: @aui-form-field-width-long;
    }

    .full-width-field {
        max-width: @aui-form-field-width-full;
    }
}
