.aui-iconfont-add {
    --aui-ig: "\f101";
}

.aui-iconfont-add-comment {
    --aui-ig: "\f102";
}

.aui-iconfont-approve {
    --aui-ig: "\f104";
}

.aui-iconfont-menu {
    --aui-ig: "\f105";
}

.aui-iconfont-chevron-double-down {
    --aui-ig: "\f106";
}

.aui-iconfont-chevron-double-left {
    --aui-ig: "\f107";
}

.aui-iconfont-chevron-double-right {
    --aui-ig: "\f108";
}

.aui-iconfont-chevron-double-up {
    --aui-ig: "\f109";
}

.aui-iconfont-attachment {
    --aui-ig: "\f10a";
}

.aui-iconfont-documents {
    --aui-ig: "\f10f";
}

.aui-iconfont-refresh {
    --aui-ig: "\f119";
}

.aui-iconfont-calendar {
    --aui-ig: "\f11a";
}

.aui-iconfont-cross {
    --aui-ig: "\f11b";
}

.aui-iconfont-chevron-right {
    --aui-ig: "\f11c";
}

.aui-iconfont-comment {
    --aui-ig: "\f11d";
}

.aui-iconfont-settings {
    --aui-ig: "\f11e";
}

.aui-iconfont-confluence {
    --aui-ig: "\f11f";
}

.aui-iconfont-copy {
    --aui-ig: "\f120";
}

.aui-iconfont-trash {
    --aui-ig: "\f122";
}

.aui-iconfont-upload {
    --aui-ig: "\f123";
}

.aui-iconfont-detail-view {
    --aui-ig: "\f124";
}

.aui-iconfont-arrow-left {
    --aui-ig: "\f125";
}

.aui-iconfont-arrow-right {
    --aui-ig: "\f126";
}

.aui-iconfont-sign-in {
    --aui-ig: "\f12a";
}

.aui-iconfont-import {
    --aui-ig: "\f12b";
}

.aui-iconfont-branch {
    --aui-ig: "\f127";
}

.aui-iconfont-commits {
    --aui-ig: "\f12c";
}

.aui-iconfont-folder-filled {
    --aui-ig: "\f131";
}

.aui-iconfont-create-fork {
    --aui-ig: "\f133";
}

.aui-iconfont-export {
    --aui-ig: "\f134";
}

.aui-iconfont-submodule {
    --aui-ig: "\f139";
}

.aui-iconfont-tag {
    --aui-ig: "\f13a";
}

.aui-iconfont-incomplete-build {
    --aui-ig: "\f13c";
}

.aui-iconfont-plan-disabled {
    --aui-ig: "\f13d";
}

.aui-iconfont-queued-build {
    --aui-ig: "\f13e";
}

.aui-iconfont-document {
    --aui-ig: "\f13f";
}

// Disabled since the "arrow-down" is using "arrow-down-small" for the backward compatibility
//.aui-iconfont-arrow-down {
//    --aui-ig: "\f140";
//}
.aui-iconfont-new-arrow-down {
    --aui-ig: "\f140";
}

.aui-iconfont-edit-filled {
    --aui-ig: "\f142";
}

.aui-iconfont-center-alignment {
    --aui-ig: "\f144";
}

.aui-iconfont-left-alignment {
    --aui-ig: "\f145";
}

.aui-iconfont-right-alignment {
    --aui-ig: "\f146";
}

.aui-iconfont-bold {
    --aui-ig: "\f147";
}

.aui-iconfont-text-color {
    --aui-ig: "\f148";
}

.aui-iconfont-emoji {
    --aui-ig: "\f149";
}

// Disabled since the "help" is using "question-circle" for the backward compatibility
//.aui-iconfont-help {
//    --aui-ig: "\f14a";
//}
.aui-iconfont-new-help {
    --aui-ig: "\f14a";
}

.aui-iconfont-horizontal-rule {
    --aui-ig: "\f14b";
}

.aui-iconfont-indent-left {
    --aui-ig: "\f14c";
}

.aui-iconfont-italic {
    --aui-ig: "\f14d";
}

.aui-iconfont-page-layout-toggle {
    --aui-ig: "\f14e";
}

.aui-iconfont-bullet-list {
    --aui-ig: "\f14f";
}

.aui-iconfont-number-list {
    --aui-ig: "\f150";
}

.aui-iconfont-table-of-contents {
    --aui-ig: "\f151";
}

.aui-iconfont-mention {
    --aui-ig: "\f152";
}

.aui-iconfont-indent-right {
    --aui-ig: "\f153";
}

.aui-iconfont-advanced {
    --aui-ig: "\f154";
}

.aui-iconfont-symbol {
    --aui-ig: "\f155";
}

.aui-iconfont-table {
    --aui-ig: "\f156";
}

.aui-iconfont-task {
    --aui-ig: "\f157";
}

.aui-iconfont-underline {
    --aui-ig: "\f158";
}

.aui-iconfont-email {
    --aui-ig: "\f159";
}

.aui-iconfont-error {
    --aui-ig: "\f15a";
}

.aui-iconfont-chevron-down {
    --aui-ig: "\f15b";
}

.aui-iconfont-flag {
    --aui-ig: "\f165";
}

.aui-iconfont-vid-full-screen-on {
    --aui-ig: "\f166";
}

.aui-iconfont-group {
    --aui-ig: "\f167";
}

.aui-iconfont-more-vertical {
    --aui-ig: "\f168";
}

.aui-iconfont-question-circle {
    --aui-ig: "\f169";
}

.aui-iconfont-home-circle {
    --aui-ig: "\f16b";
}

.aui-iconfont-image {
    --aui-ig: "\f16c";
}

.aui-iconfont-info-circle {
    --aui-ig: "\f16f";
}

.aui-iconfont-jira {
    --aui-ig: "\f170";
}

.aui-iconfont-like {
    --aui-ig: "\f173";
}

.aui-iconfont-world {
    --aui-ig: "\f175";
}

.aui-iconfont-link {
    --aui-ig: "\f176";
}

.aui-iconfont-add-circle {
    --aui-ig: "\f177";
}

.aui-iconfont-cross-circle {
    --aui-ig: "\f178";
}

.aui-iconfont-lock-filled {
    --aui-ig: "\f179";
}

.aui-iconfont-more {
    --aui-ig: "\f17f";
}

.aui-iconfont-nav-children {
    --aui-ig: "\f180";
}

.aui-iconfont-file {
    --aui-ig: "\f183";
}

.aui-iconfont-quote {
    --aui-ig: "\f186";
}

.aui-iconfont-redo {
    --aui-ig: "\f187";
}

.aui-iconfont-search {
    --aui-ig: "\f18c";
}

.aui-iconfont-share {
    --aui-ig: "\f18e";
}

.aui-iconfont-shortcut {
    --aui-ig: "\f18f";
}

.aui-iconfont-person-circle {
    --aui-ig: "\f192";
}

.aui-iconfont-star-filled {
    --aui-ig: "\f193";
}

.aui-iconfont-check {
    --aui-ig: "\f194";
}

.aui-iconfont-insert-column-before {
    --aui-ig: "\f196";
}

.aui-iconfont-remove-column {
    --aui-ig: "\f197";
}

.aui-iconfont-insert-column-after {
    --aui-ig: "\f198";
}

.aui-iconfont-copy-table-row {
    --aui-ig: "\f199";
}

.aui-iconfont-cut-table-row {
    --aui-ig: "\f19a";
}

.aui-iconfont-heading-column {
    --aui-ig: "\f19b";
}

.aui-iconfont-heading-row {
    --aui-ig: "\f19c";
}

.aui-iconfont-merge-table-cells {
    --aui-ig: "\f19d";
}

.aui-iconfont-paste-table-row {
    --aui-ig: "\f19f";
}

.aui-iconfont-remove-table {
    --aui-ig: "\f1a0";
}

.aui-iconfont-insert-row-after {
    --aui-ig: "\f1a1";
}

.aui-iconfont-remove-row {
    --aui-ig: "\f1a2";
}

.aui-iconfont-insert-row-before {
    --aui-ig: "\f1a3";
}

.aui-iconfont-split-merged-table-cells {
    --aui-ig: "\f1a4";
}

.aui-iconfont-team-calendar {
    --aui-ig: "\f1a5";
}

.aui-iconfont-recent {
    --aui-ig: "\f1a6";
}

.aui-iconfont-undo {
    --aui-ig: "\f1a7";
}

.aui-iconfont-vid-full-screen-off {
    --aui-ig: "\f1a8";
}

.aui-iconfont-unlock-filled {
    --aui-ig: "\f1a9";
}

// Disabled since the "star" is using "star-filled" for the backward compatibility
//.aui-iconfont-star {
//    --aui-ig: "\f1aa";
//}
.aui-iconfont-new-star {
    --aui-ig: "\f1aa";
}

// Disabled since the "watch" is using "watch-filled" for the backward compatibility
//.aui-iconfont-watch {
//    --aui-ig: "\f1ab";
//}
.aui-iconfont-new-watch {
    --aui-ig: "\f1ab";
}

// Disabled since the "arrow-up" is using "arrow-up-small" for the backward compatibility
//.aui-iconfont-arrow-up {
//    --aui-ig: "\f1ac";
//}
.aui-iconfont-new-arrow-up {
    --aui-ig: "\f1ac";
}

.aui-iconfont-person {
    --aui-ig: "\f1ad";
}

.aui-iconfont-watch-filled {
    --aui-ig: "\f1af";
}

.aui-iconfont-room-menu {
    --aui-ig: "\f1b1";
}

.aui-iconfont-warning {
    --aui-ig: "\f1b3";
}

.aui-iconfont-tray {
    --aui-ig: "\f1b5";
}

.aui-iconfont-vid-pause {
    --aui-ig: "\f1c1";
}

.aui-iconfont-swap {
    --aui-ig: "\f1c9";
}

.aui-iconfont-component {
    --aui-ig: "\f1cd";
}

.aui-iconfont-arrow-down-small {
    --aui-ig: "\f1d3";
}

.aui-iconfont-arrow-up-small {
    --aui-ig: "\f1d4";
}

.aui-iconfont-filter {
    --aui-ig: "\f1d6";
}

.aui-iconfont-download {
    --aui-ig: "\f1de";
}

.aui-iconfont-single-column {
    --aui-ig: "\f1e8";
}

.aui-iconfont-two-column {
    --aui-ig: "\f1e9";
}

.aui-iconfont-right-side-bar {
    --aui-ig: "\f1ea";
}

.aui-iconfont-left-side-bar {
    --aui-ig: "\f1eb";
}

.aui-iconfont-three-column-side-bars {
    --aui-ig: "\f1ec";
}

.aui-iconfont-three-column {
    --aui-ig: "\f1ed";
}

.aui-iconfont-location {
    --aui-ig: "\f1ee";
}

.aui-iconfont-radio {
    --aui-ig: "\f500";
}

.aui-iconfont-activity {
    --aui-ig: "\f1f3";
}

.aui-iconfont-add-item {
    --aui-ig: "\f1f4";
}

.aui-iconfont-addon {
    --aui-ig: "\f1f5";
}

.aui-iconfont-app-access {
    --aui-ig: "\f1f6";
}

.aui-iconfont-appswitcher,
.aui-iconfont-app-switcher {
    --aui-ig: "\f1f7";
}

.aui-iconfont-arrow-down-circle {
    --aui-ig: "\f1f8";
}

.aui-iconfont-arrow-down-left {
    --aui-ig: "\f1f9";
}

.aui-iconfont-arrow-down-right {
    --aui-ig: "\f1fa";
}

.aui-iconfont-arrow-left-circle {
    --aui-ig: "\f1fb";
}

.aui-iconfont-arrow-right-circle {
    --aui-ig: "\f1fc";
}

.aui-iconfont-arrow-up-circle {
    --aui-ig: "\f1fd";
}

.aui-iconfont-audio-circle {
    --aui-ig: "\f1fe";
}

.aui-iconfont-audio {
    --aui-ig: "\f1ff";
}

.aui-iconfont-backlog {
    --aui-ig: "\f200";
}

.aui-iconfont-billing-filled {
    --aui-ig: "\f201";
}

.aui-iconfont-billing {
    --aui-ig: "\f202";
}

.aui-iconfont-board {
    --aui-ig: "\f203";
}

.aui-iconfont-book {
    --aui-ig: "\f204";
}

.aui-iconfont-calendar-filled {
    --aui-ig: "\f205";
}

.aui-iconfont-camera-filled {
    --aui-ig: "\f206";
}

.aui-iconfont-camera-rotate {
    --aui-ig: "\f207";
}

.aui-iconfont-camera-take-picture {
    --aui-ig: "\f208";
}

.aui-iconfont-camera {
    --aui-ig: "\f209";
}

.aui-iconfont-canvas {
    --aui-ig: "\f20a";
}

.aui-iconfont-cell-color-underline {
    --aui-ig: "\f20b";
}

.aui-iconfont-cell-color {
    --aui-ig: "\f20c";
}

.aui-iconfont-check-circle-filled {
    --aui-ig: "\f20d";
}

.aui-iconfont-check-circle {
    --aui-ig: "\f20e";
}

.aui-iconfont-checkbox {
    --aui-ig: "\f20f";
}

.aui-iconfont-chevron-down-circle {
    --aui-ig: "\f210";
}

.aui-iconfont-chevron-left-circle {
    --aui-ig: "\f211";
}

.aui-iconfont-chevron-left {
    --aui-ig: "\f212";
}

.aui-iconfont-chevron-right-circle {
    --aui-ig: "\f213";
}

.aui-iconfont-chevron-up-circle {
    --aui-ig: "\f214";
}

.aui-iconfont-chevron-up {
    --aui-ig: "\f215";
}

.aui-iconfont-clone-small {
    --aui-ig: "\f216";
}

.aui-iconfont-code {
    --aui-ig: "\f217";
}

.aui-iconfont-copy-table-column {
    --aui-ig: "\f218";
}

.aui-iconfont-create-branch {
    --aui-ig: "\f219";
}

.aui-iconfont-create-pull-request {
    --aui-ig: "\f21a";
}

.aui-iconfont-credit-card {
    --aui-ig: "\f21b";
}

.aui-iconfont-credit-card-filled {
    --aui-ig: "\f21c";
}

.aui-iconfont-cut-table-column {
    --aui-ig: "\f21d";
}

.aui-iconfont-dashboard {
    --aui-ig: "\f21e";
}

.aui-iconfont-decision {
    --aui-ig: "\f21f";
}

.aui-iconfont-discover-filled {
    --aui-ig: "\f220";
}

.aui-iconfont-discover {
    --aui-ig: "\f221";
}

.aui-iconfont-document-filled {
    --aui-ig: "\f222";
}

.aui-iconfont-dropbox {
    --aui-ig: "\f223";
}

// Disabled since the "edit" is using "edit-filled" for the backward compatibility
//.aui-iconfont-edit {
//    --aui-ig: "\f224";
//}
.aui-iconfont-new-edit {
    --aui-ig: "\f224";
}

.aui-iconfont-failed-build {
    --aui-ig: "\f225";
}

.aui-iconfont-feedback {
    --aui-ig: "\f226";
}

.aui-iconfont-folder {
    --aui-ig: "\f227";
}

.aui-iconfont-followers {
    --aui-ig: "\f228";
}

.aui-iconfont-following {
    --aui-ig: "\f229";
}

.aui-iconfont-fork-small {
    --aui-ig: "\f22a";
}

.aui-iconfont-gallery {
    --aui-ig: "\f22b";
}

.aui-iconfont-google-drive {
    --aui-ig: "\f22c";
}

.aui-iconfont-google {
    --aui-ig: "\f22d";
}

.aui-iconfont-graph-bar {
    --aui-ig: "\f22e";
}

.aui-iconfont-graph-line {
    --aui-ig: "\f22f";
}

.aui-iconfont-highlights {
    --aui-ig: "\f230";
}

.aui-iconfont-home-filled {
    --aui-ig: "\f231";
}

.aui-iconfont-image-border {
    --aui-ig: "\f232";
}

.aui-iconfont-image-resize {
    --aui-ig: "\f233";
}

.aui-iconfont-info-filled {
    --aui-ig: "\f234";
}

.aui-iconfont-insert-numbered-column {
    --aui-ig: "\f235";
}

.aui-iconfont-invite-team {
    --aui-ig: "\f236";
}

.aui-iconfont-issue-raise {
    --aui-ig: "\f237";
}

.aui-iconfont-issue {
    --aui-ig: "\f238";
}

.aui-iconfont-issues {
    --aui-ig: "\f239";
}

.aui-iconfont-lightbulb-filled {
    --aui-ig: "\f23a";
}

.aui-iconfont-lightbulb {
    --aui-ig: "\f23b";
}

.aui-iconfont-link-filled {
    --aui-ig: "\f23c";
}

.aui-iconfont-list {
    --aui-ig: "\f23d";
}

.aui-iconfont-lock-circle-small {
    --aui-ig: "\f23e";
}

.aui-iconfont-lock {
    --aui-ig: "\f23f";
}

.aui-iconfont-marketplace {
    --aui-ig: "\f240";
}

.aui-iconfont-multiple-commits {
    --aui-ig: "\f241";
}

.aui-iconfont-needs-work {
    --aui-ig: "\f242";
}

.aui-iconfont-notification-all {
    --aui-ig: "\f243";
}

.aui-iconfont-notification-direct {
    --aui-ig: "\f244";
}

.aui-iconfont-notification {
    --aui-ig: "\f245";
}

.aui-iconfont-office-building-filled {
    --aui-ig: "\f246";
}

.aui-iconfont-office-building {
    --aui-ig: "\f247";
}

.aui-iconfont-open {
    --aui-ig: "\f248";
}

.aui-iconfont-overview {
    --aui-ig: "\f249";
}

.aui-iconfont-page-filled {
    --aui-ig: "\f24a";
}

.aui-iconfont-page {
    --aui-ig: "\f24b";
}

.aui-iconfont-paint-bucket {
    --aui-ig: "\f24c";
}

.aui-iconfont-paste-table-column {
    --aui-ig: "\f24d";
}

.aui-iconfont-pdf {
    --aui-ig: "\f24e";
}

.aui-iconfont-people-group {
    --aui-ig: "\f24f";
}

.aui-iconfont-people {
    --aui-ig: "\f250";
}

.aui-iconfont-portfolio {
    --aui-ig: "\f251";
}

.aui-iconfont-preferences {
    --aui-ig: "\f252";
}

.aui-iconfont-progress {
    --aui-ig: "\f253";
}

.aui-iconfont-pull-requests {
    --aui-ig: "\f254";
}

.aui-iconfont-question-filled {
    --aui-ig: "\f255";
}

.aui-iconfont-queues {
    --aui-ig: "\f256";
}

.aui-iconfont-recent-filled {
    --aui-ig: "\f257";
}

.aui-iconfont-repository-small {
    --aui-ig: "\f258";
}

.aui-iconfont-running-build {
    --aui-ig: "\f259";
}

.aui-iconfont-schedule-filled {
    --aui-ig: "\f25a";
}

.aui-iconfont-schedule {
    --aui-ig: "\f25b";
}

.aui-iconfont-screen {
    --aui-ig: "\f25c";
}

.aui-iconfont-send {
    --aui-ig: "\f25d";
}

.aui-iconfont-ship {
    --aui-ig: "\f25e";
}

.aui-iconfont-sign-out {
    --aui-ig: "\f25f";
}

.aui-iconfont-source {
    --aui-ig: "\f260";
}

.aui-iconfont-subtask {
    --aui-ig: "\f261";
}

.aui-iconfont-successful-build {
    --aui-ig: "\f262";
}

.aui-iconfont-task-list {
    --aui-ig: "\f263";
}

.aui-iconfont-text-color-underline {
    --aui-ig: "\f264";
}

.aui-iconfont-tray-empty {
    --aui-ig: "\f265";
}

.aui-iconfont-unlink {
    --aui-ig: "\f266";
}

.aui-iconfont-unlock-circle {
    --aui-ig: "\f267";
}

.aui-iconfont-unlock {
    --aui-ig: "\f268";
}

.aui-iconfont-vid-audio-muted {
    --aui-ig: "\f269";
}

.aui-iconfont-vid-audio-on {
    --aui-ig: "\f26a";
}

.aui-iconfont-vid-backward {
    --aui-ig: "\f26b";
}

.aui-iconfont-vid-forward {
    --aui-ig: "\f26c";
}

.aui-iconfont-vid-full-connection-circle {
    --aui-ig: "\f26d";
}

.aui-iconfont-vid-full-speaking-circle {
    --aui-ig: "\f26e";
}

.aui-iconfont-vid-hang-up {
    --aui-ig: "\f26f";
}

.aui-iconfont-vid-hd-circle {
    --aui-ig: "\f270";
}

.aui-iconfont-vid-play {
    --aui-ig: "\f271";
}

.aui-iconfont-vid-raised-hand {
    --aui-ig: "\f272";
}

.aui-iconfont-vid-share-screen {
    --aui-ig: "\f273";
}

.aui-iconfont-video-camera-off {
    --aui-ig: "\f274";
}

.aui-iconfont-video-circle {
    --aui-ig: "\f275";
}

.aui-iconfont-video-filled {
    --aui-ig: "\f276";
}
