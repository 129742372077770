@import (reference) './imports/global';
@import (reference) './imports/mixins';
@import (reference) './imports/aui-theme/components/forms';

@label-left-offset: 20px;
@button-border-width: @aui-form-button-border-width;
@button-focus-size: 12px;
@button-focus-border-color: @aui-focus-ring-color;
@button-focus-border: @aui-form-button-focus-border-width solid @button-focus-border-color;

@radio-offset-top: 4px;
@radio-offset-left: 0px;
@radio-glyph-offset-adjustment: 2px;

@checkbox-offset-top: 3px;
@checkbox-offset-left: 0px;
@checkbox-border-radius: @button-border-width * 2;
@checkbox-focus-border-radius: @checkbox-border-radius;

@checkbox-unchecked-icon-color: var(--aui-form-radio-unchecked-border-color);
@checkbox-unchecked-bg-color: var(--aui-form-radio-unchecked-bg-color);
@checkbox-unchecked-border-color: var(--aui-form-radio-unchecked-border-color);
@checkbox-checked-icon-color: var(--aui-form-glyph-icon-color);
@checkbox-checked-bg-color: var(--aui-form-glyph-fill-color);
@checkbox-checked-border-color: var(--aui-form-glyph-fill-color);
@checkbox-active-bg-color: var(--aui-form-checkbox-active-bg-color);
@checkbox-active-border-color: var(--aui-form-checkbox-active-border-color);
@checkbox-active-icon-color: var(--aui-form-checkbox-active-icon-color);
@checkbox-disabled-icon-color: var(--aui-form-glyph-disabled-icon-color);
@checkbox-disabled-bg-color: var(--aui-form-glyph-disabled-fill-color);
@checkbox-disabled-border-color: var(--aui-form-glyph-disabled-fill-color);

@radio-unchecked-bg-color: var(--aui-form-radio-unchecked-bg-color);
@radio-unchecked-border-color: var(--aui-form-radio-unchecked-border-color);
@radio-checked-bg-color: var(--aui-form-glyph-fill-color);
@radio-disabled-bg-color: var(--aui-form-glyph-disabled-icon-color);
@radio-disabled-border-color: var(--aui-form-glyph-disabled-fill-color);

form.aui:not(.aui-legacy-forms) {

    .aui-checkbox-focus-size() {
        display: inline-block;
        width: @aui-form-button-size + @button-border-width;
        height: @aui-form-button-size + @button-border-width;
    }
    .aui-radio-position() {
        position: absolute;
        left: @radio-offset-left;
        top: @radio-offset-top - 1px;
    }
    .aui-radio-position(iconfont) {
        position: absolute;
        left: @radio-offset-left - (@radio-glyph-offset-adjustment/2);
        top: @radio-offset-top - @radio-glyph-offset-adjustment;
    }
    .aui-radio-outline-position() {
        position: absolute;
        left: @radio-offset-left - @aui-form-button-border-width - @aui-form-radio-checkbox-outline-padding-space;
        top: @radio-offset-top - 1px - @aui-form-button-border-width - @aui-form-radio-checkbox-outline-padding-space;
    }
    .aui-checkbox-position() {
        position: absolute;
        left: @checkbox-offset-left;
        top: @checkbox-offset-top;
    }
    .aui-checkbox-outline-position() {
        position: absolute;
        left: @checkbox-offset-left - @aui-form-button-border-width - @aui-form-radio-checkbox-outline-padding-space;
        top: @checkbox-offset-top - @aui-form-button-border-width - @aui-form-radio-checkbox-outline-padding-space;
    }
    .aui-radio-disabled-style() {
        & ~ label {
            cursor: not-allowed;
            color: @aui-form-disabled-field-label-color;
        }
        & + .aui-form-glyph::before {
            cursor: not-allowed;
            color: @radio-disabled-bg-color;
            border-color: transparent;
            background: transparent;
        }
    }
    .aui-checkbox-disabled-style() {
        & ~ label {
            cursor: not-allowed;
            color: @aui-form-disabled-field-label-color;
        }
        & + .aui-form-glyph::before {
            cursor: not-allowed;
            color: @checkbox-disabled-icon-color;
            border-color: @checkbox-disabled-border-color;
            background: @checkbox-disabled-bg-color;
        }
    }
    .aui-radio-active-style(@icon) {
        height: @aui-form-button-size + 2 * @button-border-width;
        width: @aui-form-button-size + 2 * @button-border-width;
        background: @icon no-repeat -5px -5px;
        line-height: 1;
        position: absolute;
        top: @radio-offset-top - 1px;
        left: @radio-offset-left;
        content: ' ';
    }

    //
    // Field styles
    //

    .radio input[type="radio"],
    .checkbox input[type="checkbox"] {
        #aui.visually-hidden();
    }

    .radio, .checkbox {
        padding: 0 0 0 @label-left-offset;
        margin: 5px 0 0;
        position: relative;

        &:first-child {
            margin-top: 0;
        }

        // a faux glyph for a radio or checkbox element.
        // this element requires positional hacking, and for that I'm sorry.
        // it was either this element, or no ADG styles at all because of
        // our inability to handle all legitimate markup patterns with CSS only.
        .aui-form-glyph {
            // this element isn't interactive in any way itself; it merely reflects
            // the state of the `<input>` element it's attempting to emulate.
            // we need any clicks on this to be received by the `<label>` element
            // or the `<input>` element behind this one.
            pointer-events: none !important;
        }

        // AUI-5343 - direct child selector; prevents leak radio/checkbox properties to nested labels
        > label {
            position: relative;

            // we need a pseudo-element to extend the clickable area of the label
            // over where the faux glyph will be.
            // why this, and not just a negative padding? Because some products
            // put lots of content inside the field-group for radios and checkboxes,
            // and if we move the entire label to the left, the content after it will
            // have a gap that looks awkward.
            &::before {
                content: "";
                display: inline-block;
                left: @label-left-offset * -1; // move the el to take up space in to the margin created for the glyph
                position: absolute;
                width: @label-left-offset; // take up the space created for the glyph
                height: 100%;
            }

            // account for markup patterns where the input is nested inside the label
            > .aui-form-glyph::before,
            > .aui-form-glyph::after {
                margin-left: @label-left-offset * -1;
            }
        }

    }

    .radio {
        input {
            &:checked {
                & + .aui-form-glyph::before {
                    #aui.icon-pseudoelement();
                    #aui-forms.aui-radio-checkbox-size();
                    .aui-radio-position(iconfont);

                    content: @aui-glyph-radio;
                    color: @radio-checked-bg-color;
                    font-size: @aui-font-size-large;
                    line-height: 1;
                }

                &:active:not(:disabled) + .aui-form-glyph::before {
                    @icon: ~"url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZm9jdXNhYmxlPSJmYWxzZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8ZyBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxjaXJjbGUgZmlsbD0iI0RFRUJGRiIgY3g9IjEyIiBjeT0iMTIiIHI9IjciPjwvY2lyY2xlPgogICAgICAgIDxjaXJjbGUgZmlsbD0iIzAwNTJDQyIgY3g9IjEyIiBjeT0iMTIiIHI9IjIuMzM1Ij48L2NpcmNsZT4KICAgIDwvZz4KPC9zdmc+Cg==')";
                    .aui-radio-active-style(@icon);
                }

                &:disabled {
                    .aui-radio-disabled-style();
                }
            }

            &:not(:checked) {
                & + .aui-form-glyph::before {
                    #aui-forms.aui-radio-checkbox-size();
                    .aui-radio-position();

                    content: "";

                    border: @button-border-width solid @radio-unchecked-border-color;
                    background-color: @radio-unchecked-bg-color;
                    border-radius: @aui-form-button-size;
                }

                &:active:not(:disabled) + .aui-form-glyph::before {
                    @icon: ~"url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZm9jdXNhYmxlPSJmYWxzZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8ZyBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxjaXJjbGUgZmlsbD0iI0RFRUJGRiIgY3g9IjEyIiBjeT0iMTIiIHI9IjciPjwvY2lyY2xlPgogICAgPC9nPgo8L3N2Zz4K')";
                    .aui-radio-active-style(@icon);
                    border: none;
                }

                &:disabled {
                    .aui-checkbox-disabled-style();
                }
            }

            &:focus + .aui-form-glyph::after {
                #aui-forms.aui-radio-checkbox-outline-size();
                .aui-radio-outline-position();

                content: "";

                border: @button-focus-border;
                border-radius: @aui-form-button-size;
                background-color: transparent;
            }
        }
    }

    .checkbox {
        input {
            &:checked {
                & + .aui-form-glyph::before {
                    #aui.icon-pseudoelement();
                    #aui-forms.aui-radio-checkbox-size();
                    .aui-checkbox-position();

                    content: @aui-glyph-check;
                    color: @checkbox-checked-icon-color;
                    font-size: (@aui-font-size-large / 2);
                    line-height: @aui-form-button-size;
                    font-weight: @aui-font-weight-bold;
                    text-align: center;

                    border: @button-border-width solid @checkbox-checked-border-color;
                    border-radius: @checkbox-border-radius;
                    background-color: @checkbox-checked-bg-color;
                }

                &:active + .aui-form-glyph::before {
                    color: @checkbox-active-icon-color;
                    background-color: @checkbox-active-bg-color;
                    border-color: @checkbox-active-border-color;
                }

                &:disabled {
                    .aui-checkbox-disabled-style();
                }
            }

            &:not(:checked) {
                & + .aui-form-glyph::before {
                    #aui-forms.aui-radio-checkbox-size();
                    .aui-checkbox-position();

                    content: "";

                    border: @button-border-width solid @checkbox-unchecked-border-color;
                    background-color: @checkbox-unchecked-bg-color;
                    border-radius: @checkbox-border-radius;
                }

                &:active + .aui-form-glyph::before {
                    background-color: @checkbox-active-bg-color;
                    border-color: @checkbox-active-bg-color;
                }

                &:disabled {
                    .aui-checkbox-disabled-style();
                }
            }

            &:focus + .aui-form-glyph::after {
                #aui-forms.aui-radio-checkbox-outline-size();
                .aui-checkbox-outline-position();

                content: "";

                border: @button-focus-border;
                border-radius: @checkbox-border-radius;
                background-color: transparent;
            }
        }
    }

}
