@import (reference) '../aui-theme/core/colors';

@aui-focus-ring-color: var(--aui-focus);

#aui {
    .with-focus-ring(@color: @aui-focus-ring-color; @outline-width: 2px; @inset: false) {
        outline-style: none;
        & when (@inset = true) {
            box-shadow+: inset 0 0 0 @outline-width @color;
        }
        & when (@inset = false) {
            box-shadow+: 0 0 0 @outline-width @color;
        }
    }

    .with-focus-border(@color: @aui-focus-ring-color, @outline-width: 2px) {
        outline: none;
        border-width: @outline-width;
        border-color: @color;

        &:focus-visible {
            // Undoing the implementation in .with-focus-ring() mixin
            box-shadow: unset;
        }
    }

    // Add a focus style to the element
    .focus(@rules) {
        &:focus {
            @rules();
        }
    }

    // Adds a focus style to the element, but only when they are activated by keyboard, not mouse.
    // Will always render a focus style if `aui-no-focusvisible` is added to the html tag.
    .focus-visible(@rules) {
        .hide-outline() {
            outline-style: none;
            outline-offset: unset;
        }

        html.aui-no-focusvisible &:focus {
            @rules();
        }

        // Remove default focus styles for mouse users ONLY if
        // :focus-visible (or its polyfill) is supported on this platform.
        // The selectors are discrete because CSS ignores an entire ruleset if one selector is invalid.
        html:not(.aui-no-focusvisible).js-focus-visible &:focus:not(.focus-visible) { .hide-outline(); }
        html:not(.aui-no-focusvisible) &:focus:not(:focus-visible) { .hide-outline(); }

        // Affect the potential CSS standard pseudo-selector
        // Its mozilla-specific implementation, and
        // a CSS class added by the WICG polyfill.
        // The selectors are discrete because CSS ignores an entire ruleset if one selector is invalid.
        html:not(.aui-no-focusvisible).js-focus-visible &.focus-visible { @rules(); }
        html:not(.aui-no-focusvisible) &:focus-visible { @rules(); }
    }
}
