@import (reference) '../theme';

@aui-inline-dialog-bg-color: var(--aui-inline-dialog-bg-color);
@aui-inline-dialog-border-color: var(--aui-inline-dialog-border-color);

#aui-inline-dialog {
    .aui-inline-dialog-style() {
        #aui.shadow.z200();
        background: @aui-inline-dialog-bg-color;
        border: var(--aui-inline-dialog-border-width) solid @aui-inline-dialog-border-color;
        border-radius: @aui-border-radius-small;
        padding: @aui-grid * 2;
    }

    .form-interop() {
        /* Inline Dialog + Forms Integration - top labels and full width fields supported */
        /* funny numbers to add up to 20px spacing in various places (border to cap height/baseline) */
        form.aui h2:first-child {
            border-bottom: 1px solid @aui-inline-dialog-border-color;
            margin: -7px 0 11px 0;
            padding: 0 0 12px 0;
        }
        form.aui .buttons-container {
            margin-top: 16px;
            padding: 0;
        }
        .submit + .cancel {
            margin-left: 0;
        }
    }
}
