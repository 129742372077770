@import (reference) './imports/global';

/**
 * BASE THEME
 */
@import './imports/aui-theme/theme.less';

/**
 * RESET
 */
html, body, p, div, h1, h2, h3, h4, h5, h6, img, pre, form, fieldset {
    margin: 0;
    padding: 0;
}
ul, ol, dl {
    margin: 0;
}
img, fieldset {
    border: 0;
}

// https://github.com/necolas/normalize.css
// Customised to remove styles for unsupported browsers

// Render these elements consistently in IE.
details,
summary,
main {
    display: block;
}

// Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
progress {
    vertical-align: baseline;
}

// Prevent modern browsers from displaying `audio` without controls.
// Remove excess height in iOS 5 devices.
audio:not([controls]) {
    display: none;
    height: 0;
}

// Prevent iOS defaulting to push-button which ignores many styles unless a bg image is set
button,
input[type="button"],
input[type="submit"],
input[type="reset"] {
    -webkit-appearance: button;
    font-family: inherit;
}

// Firefox-specific adjustments
.firefox({
    img {
        font-size: 0;
    }
    img:-moz-broken {
        font-size: inherit;
    }
});

[hidden] {
    display: none !important;
}
