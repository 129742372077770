@import (reference) './imports/global';
@import (reference) './imports/aui-theme/core/colors.less';

.aui-avatar-size(@type, @size) {
    &.aui-avatar-@{type} {
        --aui-avatar-size: @size;
    }
}

.aui-avatar-border-size(@type, @size, @border-radius: (@size * @aui-avatar-border-radius-ratio)) {
    &.aui-avatar-@{type} {
        --aui-avatar-radius: @border-radius;
    }
}

.aui-avatar-group-size(@type, @size) {
    &.aui-avatar-group-@{type} {
        --aui-avatar-size: calc(@size + 3px);
    }
}

// AUI avatar component
.aui-avatar {
    --aui-avatar-size: @aui-avatar-size-medium;

    box-sizing: border-box;
    display: inline-block;
    position: relative;
    vertical-align: text-bottom;
    line-height: 0;

    &-inner {
        display: flex;
        justify-content: center;
        align-content: center;

        height: var(--aui-avatar-size);
        max-width: var(--aui-avatar-size);
        max-height: var(--aui-avatar-size);
        width: var(--aui-avatar-size);
        border-radius: 100%;
        overflow: hidden;

        #aui-themes.when-design-tokens({
            box-shadow: 0 0 0 2px var(--ds-surface-overlay, #FFFFFF);
        })
    }

    & img {
        display: block;
        height: 100%;
        margin: auto;
        width: 100%;
    }

    .aui-avatar-size(xsmall, @aui-avatar-size-xsmall);
    .aui-avatar-size(small, @aui-avatar-size-small);
    .aui-avatar-size(medium, @aui-avatar-size-medium);
    .aui-avatar-size(large, @aui-avatar-size-large);
    .aui-avatar-size(xlarge, @aui-avatar-size-xlarge);
    .aui-avatar-size(xxlarge, @aui-avatar-size-xxlarge);
    .aui-avatar-size(xxxlarge, @aui-avatar-size-xxxlarge);

    // Project avatars - circular and different sizes
    &-project {
        --aui-avatar-radius: 3px;

        border-radius: var(--aui-avatar-radius);

        .aui-avatar-inner {
            border-radius: var(--aui-avatar-radius);
        }

        .aui-avatar-border-size(xxxlarge, @aui-avatar-size-xxxlarge, 12px);
        .aui-avatar-border-size(xxlarge, @aui-avatar-size-xxlarge, 6px);
        .aui-avatar-border-size(xlarge, @aui-avatar-size-xlarge, 6px);
        .aui-avatar-border-size(large, @aui-avatar-size-large, 3px);
        .aui-avatar-border-size(medium, @aui-avatar-size-medium, 3px);
        .aui-avatar-border-size(small, @aui-avatar-size-small, 2px);
        .aui-avatar-border-size(xsmall, @aui-avatar-size-xsmall, 2px);
    }

    &-badged {
        --aui-avatar-badged-size: calc(var(--aui-avatar-size) / 4);

        position: absolute;
        font-size: 0;
        line-height: 0;
        width: var(--aui-avatar-badged-size);
        height: var(--aui-avatar-badged-size);
        overflow: hidden;
        border: 3px solid var(--aui-avatar-outline);
        border-radius: 100%;

        &-top-start {
            top: 0;
            left: 0;
        }

        &-top-end {
            top: 0;
            right: 0;
        }
        &-bottom-start {
            bottom: 0;
            left: 0;
        }
        &-bottom-end {
            bottom: 0;
            right: 0;
        }
    }
}

// AUI avatars group component
.aui-avatar-group {
    --aui-avatar-size: @aui-avatar-size-medium;

    position: relative;
    margin-top: calc(var(--aui-avatar-size) / 4);
    display: block;
    height: var(--aui-avatar-size);

    &-item {
        position: absolute;
        bottom: 0;
    }

    &-dropdown {
        position: absolute;
        bottom: 0;
        border: 0;
        overflow: visible;

        &-button {
            cursor: pointer;
            border: none;
            color: var(--aui-avatar-text);
            display: grid;
            place-items: center;
            background-color: var(--aui-body-background);
            font-size: calc(var(--aui-avatar-size) / 3);
        }
        &-menu {
            #aui.shadow.z200();
            box-sizing: border-box;
            width: max-content;
            max-width: 300px;
            padding: @aui-dropdown-group-spacing 0;
            display: none;
            background: var(--aui-dropdown-bg-color);
            border: 0 solid var(--aui-dropdown-border-color);
            border-radius: 3px;
            position: absolute;
            top: var(--aui-avatar-size);
            z-index: 3000;
        }
        &-show > &-menu {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .aui-avatar-group-size(xsmall, @aui-avatar-size-xsmall);
    .aui-avatar-group-size(small, @aui-avatar-size-small);
    .aui-avatar-group-size(medium, @aui-avatar-size-medium);
    .aui-avatar-group-size(large, @aui-avatar-size-large);
    .aui-avatar-group-size(xlarge, @aui-avatar-size-xlarge);
    .aui-avatar-group-size(xxlarge, @aui-avatar-size-xxlarge);
    .aui-avatar-group-size(xxxlarge, @aui-avatar-size-xxxlarge);
}
