@import (reference) './imports/global';

/**
 * LINK
 */


a {
    color: var(--aui-link-color);
    text-decoration: var(--aui-link-decoration);
}

a:hover {
    color: var(--aui-link-hover-color);
    text-decoration: var(--aui-link-hover-decoration);
}

a:focus,
a:active {
    text-decoration: var(--aui-link-decoration);
}

a:active {
    color: var(--aui-link-active-color);
}
