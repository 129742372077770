@import (reference) './imports/global';

/*
* Icon Font
* - use when you need to change the colour of the icon via CSS - eg on hover or to match Look and Feel customisations
*
* Ranges
*
* UTF+E001 - UTF+E3FF - Global
* UTF+E400 - UTF+E7FF - Conf
* UTF+E800 - UTF+EBFF - JIRA
* UTF+EC00 - UTF+EFFF - Dev Tools
*/

.aui-iconfont-add {
    --aui-ig: "\f101";
}

.aui-iconfont-add-comment {
    --aui-ig: "\f102";
}

.aui-iconfont-add-small {
    --aui-ig: "\f103";
}

.aui-iconfont-approve {
    --aui-ig: "\f104";
}

.aui-iconfont-appswitcher {
    --aui-ig: "\f105";
}

.aui-iconfont-arrows-down {
    --aui-ig: "\f106";
}

.aui-iconfont-arrows-left {
    --aui-ig: "\f107";
}

.aui-iconfont-arrows-right {
    --aui-ig: "\f108";
}

.aui-iconfont-arrows-up {
    --aui-ig: "\f109";
}

.aui-iconfont-attachment {
    --aui-ig: "\f10a";
}

.aui-iconfont-attachment-small {
    --aui-ig: "\f10b";
}

.aui-iconfont-autocomplete-date {
    --aui-ig: "\f10c";
}

.aui-iconfont-back-page {
    --aui-ig: "\f10d";
}

.aui-iconfont-blogroll {
    --aui-ig: "\f10e";
}

.aui-iconfont-bp-decisions {
    --aui-ig: "\f10f";
}

.aui-iconfont-bp-default {
    --aui-ig: "\f110";
}

.aui-iconfont-bp-files {
    --aui-ig: "\f111";
}

.aui-iconfont-bp-requirements {
    --aui-ig: "\f112";
}

.aui-iconfont-bp-howto {
    --aui-ig: "\f113";
}

.aui-iconfont-bp-jira {
    --aui-ig: "\f114";
}

.aui-iconfont-bp-meeting {
    --aui-ig: "\f115";
}

.aui-iconfont-bp-retrospective {
    --aui-ig: "\f116";
}

.aui-iconfont-bp-sharedlinks {
    --aui-ig: "\f117";
}

.aui-iconfont-bp-troubleshooting {
    --aui-ig: "\f118";
}

.aui-iconfont-build {
    --aui-ig: "\f119";
}

.aui-iconfont-calendar {
    --aui-ig: "\f11a";
}

.aui-iconfont-close-dialog {
    --aui-ig: "\f11b";
}

.aui-iconfont-collapsed {
    --aui-ig: "\f11c";
}

.aui-iconfont-comment {
    --aui-ig: "\f11d";
}

.aui-iconfont-configure {
    --aui-ig: "\f11e";
}

.aui-iconfont-confluence {
    --aui-ig: "\f11f";
}

.aui-iconfont-copy-clipboard {
    --aui-ig: "\f120";
}

.aui-iconfont-custom-bullet {
    --aui-ig: "\f121";
}

.aui-iconfont-delete {
    --aui-ig: "\f122";
}

.aui-iconfont-deploy {
    --aui-ig: "\f123";
}

.aui-iconfont-details {
    --aui-ig: "\f124";
}

.aui-iconfont-devtools-arrow-left {
    --aui-ig: "\f125";
}

.aui-iconfont-devtools-arrow-right {
    --aui-ig: "\f126";
}

.aui-iconfont-devtools-branch {
    --aui-ig: "\f127";
}

.aui-iconfont-devtools-branch-small {
    --aui-ig: "\f128";
}

.aui-iconfont-devtools-browse-up {
    --aui-ig: "\f129";
}

.aui-iconfont-devtools-checkout {
    --aui-ig: "\f12a";
}

.aui-iconfont-devtools-clone {
    --aui-ig: "\f12b";
}

.aui-iconfont-devtools-commit {
    --aui-ig: "\f12c";
}

.aui-iconfont-devtools-compare {
    --aui-ig: "\f12d";
}

.aui-iconfont-devtools-file {
    --aui-ig: "\f12e";
}

.aui-iconfont-devtools-file-binary {
    --aui-ig: "\f12f";
}

.aui-iconfont-devtools-file-commented {
    --aui-ig: "\f130";
}

.aui-iconfont-devtools-folder-closed {
    --aui-ig: "\f131";
}

.aui-iconfont-devtools-folder-open {
    --aui-ig: "\f132";
}

.aui-iconfont-devtools-fork {
    --aui-ig: "\f133";
}

.aui-iconfont-devtools-pull-request {
    --aui-ig: "\f134";
}

.aui-iconfont-devtools-repository {
    --aui-ig: "\f135";
}

.aui-iconfont-devtools-repository-forked {
    --aui-ig: "\f136";
}

.aui-iconfont-devtools-repository-locked {
    --aui-ig: "\f137";
}

.aui-iconfont-devtools-side-diff {
    --aui-ig: "\f138";
}

.aui-iconfont-devtools-submodule {
    --aui-ig: "\f139";
}

.aui-iconfont-devtools-tag {
    --aui-ig: "\f13a";
}

.aui-iconfont-devtools-tag-small {
    --aui-ig: "\f13b";
}

.aui-iconfont-devtools-task-cancelled {
    --aui-ig: "\f13c";
}

.aui-iconfont-devtools-task-disabled {
    --aui-ig: "\f13d";
}

.aui-iconfont-devtools-task-in-progress {
    --aui-ig: "\f13e";
}

.aui-iconfont-doc {
    --aui-ig: "\f13f";
}

.aui-iconfont-down {
    --aui-ig: "\f140";
}

.aui-iconfont-drag-vertical {
    --aui-ig: "\f141";
}

.aui-iconfont-edit {
    --aui-ig: "\f142";
}

.aui-iconfont-edit-small {
    --aui-ig: "\f143";
}

.aui-iconfont-editor-align-center {
    --aui-ig: "\f144";
}

.aui-iconfont-editor-align-left {
    --aui-ig: "\f145";
}

.aui-iconfont-editor-align-right {
    --aui-ig: "\f146";
}

.aui-iconfont-editor-bold {
    --aui-ig: "\f147";
}

.aui-iconfont-editor-color {
    --aui-ig: "\f148";
}

.aui-iconfont-editor-emoticon {
    --aui-ig: "\f149";
}

.aui-iconfont-editor-help {
    --aui-ig: "\f14a";
}

.aui-iconfont-editor-hr {
    --aui-ig: "\f14b";
}

.aui-iconfont-editor-indent {
    --aui-ig: "\f14c";
}

.aui-iconfont-editor-italic {
    --aui-ig: "\f14d";
}

.aui-iconfont-editor-layout {
    --aui-ig: "\f14e";
}

.aui-iconfont-editor-list-bullet {
    --aui-ig: "\f14f";
}

.aui-iconfont-editor-list-number {
    --aui-ig: "\f150";
}

.aui-iconfont-editor-macro-toc {
    --aui-ig: "\f151";
}

.aui-iconfont-editor-mention {
    --aui-ig: "\f152";
}

.aui-iconfont-editor-outdent {
    --aui-ig: "\f153";
}

.aui-iconfont-editor-styles {
    --aui-ig: "\f154";
}

.aui-iconfont-editor-symbol {
    --aui-ig: "\f155";
}

.aui-iconfont-editor-table {
    --aui-ig: "\f156";
}

.aui-iconfont-editor-task {
    --aui-ig: "\f157";
}

.aui-iconfont-editor-underline {
    --aui-ig: "\f158";
}

.aui-iconfont-email {
    --aui-ig: "\f159";
}

.aui-iconfont-error {
    --aui-ig: "\f15a";
}

.aui-iconfont-expanded {
    --aui-ig: "\f15b";
}

.aui-iconfont-file-code {
    --aui-ig: "\f15c";
}

.aui-iconfont-file-doc {
    --aui-ig: "\f15d";
}

.aui-iconfont-file-java {
    --aui-ig: "\f15e";
}

.aui-iconfont-file-pdf {
    --aui-ig: "\f15f";
}

.aui-iconfont-file-ppt {
    --aui-ig: "\f160";
}

.aui-iconfont-file-txt {
    --aui-ig: "\f161";
}

.aui-iconfont-file-wav {
    --aui-ig: "\f162";
}

.aui-iconfont-file-xls {
    --aui-ig: "\f163";
}

.aui-iconfont-file-zip {
    --aui-ig: "\f164";
}

.aui-iconfont-flag {
    --aui-ig: "\f165";
}

.aui-iconfont-focus {
    --aui-ig: "\f166";
}

.aui-iconfont-group {
    --aui-ig: "\f167";
}

.aui-iconfont-handle-horizontal {
    --aui-ig: "\f168";
}

.aui-iconfont-help {
    --aui-ig: "\f169";
}

.aui-iconfont-hipchat {
    --aui-ig: "\f16a";
}

.aui-iconfont-homepage {
    --aui-ig: "\f16b";
}

.aui-iconfont-image {
    --aui-ig: "\f16c";
}

.aui-iconfont-image-extrasmall {
    --aui-ig: "\f16d";
}

.aui-iconfont-image-small {
    --aui-ig: "\f16e";
}

.aui-iconfont-info {
    --aui-ig: "\f16f";
}

.aui-iconfont-jira {
    --aui-ig: "\f170";
}

.aui-iconfont-jira-completed-task {
    --aui-ig: "\f171";
}

.aui-iconfont-jira-test-session {
    --aui-ig: "\f172";
}

.aui-iconfont-like {
    --aui-ig: "\f173";
}

.aui-iconfont-like-small {
    --aui-ig: "\f174";
}

.aui-iconfont-weblink {
    --aui-ig: "\f175";
}

.aui-iconfont-link {
    --aui-ig: "\f176";
}

.aui-iconfont-list-add {
    --aui-ig: "\f177";
}

.aui-iconfont-list-remove {
    --aui-ig: "\f178";
}

.aui-iconfont-locked {
    --aui-ig: "\f179";
}

.aui-iconfont-locked-small {
    --aui-ig: "\f17a";
}

.aui-iconfont-macro-code {
    --aui-ig: "\f17b";
}

.aui-iconfont-macro-default {
    --aui-ig: "\f17c";
}

.aui-iconfont-macro-gallery {
    --aui-ig: "\f17d";
}

.aui-iconfont-macro-status {
    --aui-ig: "\f17e";
}

.aui-iconfont-more {
    --aui-ig: "\f17f";
}

.aui-iconfont-nav-children {
    --aui-ig: "\f180";
}

.aui-iconfont-page-blank {
    --aui-ig: "\f181";
}

.aui-iconfont-page-blogpost {
    --aui-ig: "\f182";
}

.aui-iconfont-page-default {
    --aui-ig: "\f183";
}

.aui-iconfont-page-template {
    --aui-ig: "\f184";
}

.aui-iconfont-pages {
    --aui-ig: "\f185";
}

.aui-iconfont-quote {
    --aui-ig: "\f186";
}

.aui-iconfont-redo {
    --aui-ig: "\f187";
}

.aui-iconfont-remove {
    --aui-ig: "\f188";
}

.aui-iconfont-remove-label {
    --aui-ig: "\f189";
}

.aui-iconfont-review {
    --aui-ig: "\f18a";
}

.aui-iconfont-rss {
    --aui-ig: "\f18b";
}

.aui-iconfont-search {
    --aui-ig: "\f18c";
}

.aui-iconfont-search-small {
    --aui-ig: "\f18d";
}

.aui-iconfont-share {
    --aui-ig: "\f18e";
}

.aui-iconfont-sidebar-link {
    --aui-ig: "\f18f";
}

.aui-iconfont-sourcetree {
    --aui-ig: "\f190";
}

.aui-iconfont-space-default {
    --aui-ig: "\f191";
}

.aui-iconfont-space-personal {
    --aui-ig: "\f192";
}

.aui-iconfont-star {
    --aui-ig: "\f193";
}

.aui-iconfont-success {
    --aui-ig: "\f194";
}

.aui-iconfont-table-bg {
    --aui-ig: "\f195";
}

.aui-iconfont-table-col-left {
    --aui-ig: "\f196";
}

.aui-iconfont-table-col-remove {
    --aui-ig: "\f197";
}

.aui-iconfont-table-col-right {
    --aui-ig: "\f198";
}

.aui-iconfont-table-copy-row {
    --aui-ig: "\f199";
}

.aui-iconfont-table-cut-row {
    --aui-ig: "\f19a";
}

.aui-iconfont-table-header-column {
    --aui-ig: "\f19b";
}

.aui-iconfont-table-header-row {
    --aui-ig: "\f19c";
}

.aui-iconfont-table-merge {
    --aui-ig: "\f19d";
}

.aui-iconfont-table-no-bg {
    --aui-ig: "\f19e";
}

.aui-iconfont-table-paste-row {
    --aui-ig: "\f19f";
}

.aui-iconfont-table-remove {
    --aui-ig: "\f1a0";
}

.aui-iconfont-table-row-down {
    --aui-ig: "\f1a1";
}

.aui-iconfont-table-row-remove {
    --aui-ig: "\f1a2";
}

.aui-iconfont-table-row-up {
    --aui-ig: "\f1a3";
}

.aui-iconfont-table-split {
    --aui-ig: "\f1a4";
}

.aui-iconfont-teamcals {
    --aui-ig: "\f1a5";
}

.aui-iconfont-time {
    --aui-ig: "\f1a6";
}

.aui-iconfont-undo {
    --aui-ig: "\f1a7";
}

.aui-iconfont-unfocus {
    --aui-ig: "\f1a8";
}

.aui-iconfont-unlocked {
    --aui-ig: "\f1a9";
}

.aui-iconfont-unstar {
    --aui-ig: "\f1aa";
}

.aui-iconfont-unwatch {
    --aui-ig: "\f1ab";
}

.aui-iconfont-up {
    --aui-ig: "\f1ac";
}

.aui-iconfont-user {
    --aui-ig: "\f1ad";
}

.aui-iconfont-user-status {
    --aui-ig: "\f1ae";
}

.aui-iconfont-view {
    --aui-ig: "\f1af";
}

.aui-iconfont-view-card {
    --aui-ig: "\f1b0";
}

.aui-iconfont-view-list {
    --aui-ig: "\f1b1";
}

.aui-iconfont-view-table {
    --aui-ig: "\f1b2";
}

.aui-iconfont-warning {
    --aui-ig: "\f1b3";
}

.aui-iconfont-watch {
    --aui-ig: "\f1b4";
}

.aui-iconfont-workbox {
    --aui-ig: "\f1b5";
}

.aui-iconfont-workbox-empty {
    --aui-ig: "\f1b6";
}

.aui-iconfont-configure-columns {
    --aui-ig: "\f1b7";
}

.aui-iconfont-export {
    --aui-ig: "\f1b8";
}

.aui-iconfont-export-list {
    --aui-ig: "\f1b9";
}

.aui-iconfont-file-image {
    --aui-ig: "\f1ba";
}

.aui-iconfont-admin-fusion {
    --aui-ig: "\f1bb";
}

.aui-iconfont-admin-jira-fields {
    --aui-ig: "\f1bc";
}

.aui-iconfont-admin-issue {
    --aui-ig: "\f1bd";
}

.aui-iconfont-admin-notifications {
    --aui-ig: "\f1be";
}

.aui-iconfont-admin-roles {
    --aui-ig: "\f1bf";
}

.aui-iconfont-admin-jira-screens {
    --aui-ig: "\f1c0";
}

.aui-iconfont-pause {
    --aui-ig: "\f1c1";
}

.aui-iconfont-priority-highest {
    --aui-ig: "\f1c2";
}

.aui-iconfont-priority-high {
    --aui-ig: "\f1c3";
}

.aui-iconfont-priority-medium {
    --aui-ig: "\f1c4";
}

.aui-iconfont-priority-low {
    --aui-ig: "\f1c5";
}

.aui-iconfont-priority-lowest {
    --aui-ig: "\f1c6";
}

.aui-iconfont-refresh-small {
    --aui-ig: "\f1c7";
}

.aui-iconfont-share-list {
    --aui-ig: "\f1c8";
}

.aui-iconfont-switch-small {
    --aui-ig: "\f1c9";
}

.aui-iconfont-version {
    --aui-ig: "\f1ca";
}

.aui-iconfont-workflow {
    --aui-ig: "\f1cb";
}

.aui-iconfont-admin-jira-settings {
    --aui-ig: "\f1cc";
}

.aui-iconfont-component {
    --aui-ig: "\f1cd";
}

.aui-iconfont-reopen {
    --aui-ig: "\f1ce";
}

.aui-iconfont-roadmap {
    --aui-ig: "\f1cf";
}

.aui-iconfont-deploy-success {
    --aui-ig: "\f1d0";
}

.aui-iconfont-deploy-fail {
    --aui-ig: "\f1d1";
}

.aui-iconfont-file-generic {
    --aui-ig: "\f1d2";
}

.aui-iconfont-arrow-down {
    --aui-ig: "\f1d3";
}

.aui-iconfont-arrow-up {
    --aui-ig: "\f1d4";
}

.aui-iconfont-file-video {
    --aui-ig: "\f1d5";
}

.aui-iconfont-filter {
    --aui-ig: "\f1d6";
}

.aui-iconfont-table-copy-column {
    --aui-ig: "\f1d7";
}

.aui-iconfont-table-paste-column {
    --aui-ig: "\f1d8";
}

.aui-iconfont-table-cut-column {
    --aui-ig: "\f1d9";
}

.aui-iconfont-maximise-small {
    --aui-ig: "\f1da";
}

.aui-iconfont-minimise-small {
    --aui-ig: "\f1db";
}

.aui-iconfont-more-small {
    --aui-ig: "\f1dc";
}

.aui-iconfont-move-small {
    --aui-ig: "\f1dd";
}

.aui-iconfont-download {
    --aui-ig: "\f1de";
}

.aui-iconfont-upload {
    --aui-ig: "\f1df";
}

.aui-iconfont-version-unreleased {
    --aui-ig: "\f1e0";
}

.aui-iconfont-puzzle {
    --aui-ig: "\f1e1";
}

.aui-iconfont-editor-files {
    --aui-ig: "\f1e2";
}

.aui-iconfont-bp-sprint {
    --aui-ig: "\f1e3";
}

.aui-iconfont-jira-issues {
    --aui-ig: "\f1e4";
}

.aui-iconfont-bitbucket {
    --aui-ig: "\f1e5";
}

.aui-iconfont-blogroll-large {
    --aui-ig: "\f1e6";
}

.aui-iconfont-email-large {
    --aui-ig: "\f1e7";
}

.aui-iconfont-layout-1col-large {
    --aui-ig: "\f1e8";
}

.aui-iconfont-layout-2col-large {
    --aui-ig: "\f1e9";
}

.aui-iconfont-layout-2col-left-large {
    --aui-ig: "\f1ea";
}

.aui-iconfont-layout-2col-right-large {
    --aui-ig: "\f1eb";
}

.aui-iconfont-layout-3col-center-large {
    --aui-ig: "\f1ec";
}

.aui-iconfont-layout-3col-large {
    --aui-ig: "\f1ed";
}

.aui-iconfont-nav-children-large {
    --aui-ig: "\f1ee";
}

.aui-iconfont-pages-large {
    --aui-ig: "\f1ef";
}

.aui-iconfont-sidebar-link-large {
    --aui-ig: "\f1f0";
}

.aui-iconfont-teamcals-large {
    --aui-ig: "\f1f1";
}

.aui-iconfont-user-large {
    --aui-ig: "\f1f2";
}
