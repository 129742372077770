@import (reference) '../theme';
@import (reference) '../../mixins/shadows';

@aui-dropdown-border-radius: @aui-border-radius-small;

// space between a dropdown trigger and the dropdown itself
@aui-dropdown-trigger-offset: 3px;

// amount of space between a dropdown item and an item group border
@aui-dropdown-group-spacing: 3px;

@aui-dropdown-bg-color: var(--aui-dropdown-bg-color);
@aui-dropdown-border-color: var(--aui-dropdown-border-color); // to match the lightness of the faux border in the "z200" shadow
@aui-dropdown-heading-text-color: var(--aui-itemheading-text);

#aui-dropdowns {
    .aui-dropdown-style() {
        #aui.shadow.z200();
        background: @aui-dropdown-bg-color;
        border: 0 solid @aui-dropdown-border-color;
        border-radius: @aui-dropdown-border-radius;
        color: var(--aui-item-text);
    }
}
