@import (reference) './imports/global';
@import (reference) './imports/aui-theme/components/forms';

.ffi {
    @aui-ffi-field-bg-color: @aui-form-field-default-bg-color;
    @aui-ffi-field-bg-color-disabled: @aui-form-disabled-field-bg-color;
    @aui-ffi-field-border-color: @aui-form-field-border-color;
    @aui-ffi-field-border-color-focused: @aui-form-field-border-color;
    @aui-ffi-field-border-radius: @aui-form-field-border-radius;

    @aui-ffi-value-text-color: @aui-ffi-field-text-color;

    @aui-ffi-field-text-color: @aui-form-field-default-text-color;
    @aui-ffi-field-text-color-disabled: @aui-form-disabled-field-text-color;
    @aui-ffi-field-font-size: @aui-font-size-medium;
    @aui-ffi-field-default-width: @aui-form-field-width-default;
    @aui-ffi-field-horizontal-padding: 5px;

    @aui-ffi-button-horizontal-padding: 10px;

    @aui-ffi-button-bg-color: @aui-button-default-bg-color;
    @aui-ffi-button-border-color: @aui-ffi-field-border-color;
    @aui-ffi-button-text-color: @aui-button-default-text-color;

    @aui-ffi-button-bg-color-hover: @aui-button-default-hover-bg-color;
    @aui-ffi-button-text-color-hover: @aui-button-default-text-color;

    @aui-ffi-button-bg-color-active: @aui-button-default-active-bg-color;
    @aui-ffi-button-text-color-active: @aui-button-default-active-text-color;

    @aui-ffi-button-bg-color-disabled: @aui-button-default-disabled-bg-color;
    @aui-ffi-button-border-color-disabled: @aui-ffi-button-border-color;
    @aui-ffi-button-text-color-disabled: @aui-button-default-disabled-text-color;


    border-radius: @aui-ffi-field-border-radius;
    box-shadow: none;
    color: @aui-ffi-field-text-color;
    font-size: @aui-ffi-field-font-size;
    max-width: @aui-ffi-field-default-width;

    form.aui:not(.aui-legacy-forms) .field-group & {
        float: none;
        margin: 0;
        padding: 0;
        word-wrap: normal;
        text-align: left;
        width: 100%;

        background-color: @aui-ffi-field-bg-color;
        border-color: @aui-ffi-field-border-color;
    }

    form.aui.aui-legacy-forms .field-group & {
        #aui-legacy-forms.colors();

        float: none;
        margin: 0;
        padding: 0;
        word-wrap: normal;
        text-align: left;
        width: 100%;

        background-color: @aui-legacy-form-field-default-bg-color;
        border-color: @aui-legacy-form-field-border-color;
    }

    form.aui.top-label .field-group & {
        display: inline-block;
        margin: 0;
        width: 100%;
    }
    form.aui.long-label .field-group & {
        margin: 0;
        width: 100%;
    }

    /* The actual input - made opaque but styled so the cursor positions well */
    input[type="file"] {
        padding: 0 @aui-ffi-field-horizontal-padding;
    }

    // The field value
    &[data-ffi-value]:after {
        color: @aui-ffi-value-text-color;
        padding: 0 @aui-ffi-field-horizontal-padding;
    }

    // The "browse" button
    &:before {
        background: @aui-ffi-button-bg-color;
        border-left-color: @aui-ffi-button-border-color;
        color: @aui-ffi-button-text-color;
        padding: 0 @aui-ffi-button-horizontal-padding;
    }
    &:hover:before {
        background: @aui-ffi-button-bg-color-hover;
        color: @aui-ffi-button-text-color-hover;
    }
    &:active {
        &:before,
        &:hover:before {
            background-color: @aui-ffi-button-bg-color-active;
            background-image: none;
            color: @aui-ffi-button-text-color-active;
            text-decoration:none;
            text-shadow: none;
        }
    }

    // focusing the field
    &.is-focused {
        border-color: @aui-ffi-field-border-color-focused;
        #aui.with-focus-ring();
    }

    // disabled field
    &.is-disabled {
        background-color: @aui-ffi-field-bg-color-disabled;
        color: @aui-ffi-field-text-color-disabled;

        // the button
        &:before,
        &:hover:before,
        &:active:before,
        &:active:hover:before {
            background-color: @aui-ffi-button-bg-color-disabled;
            border-color: @aui-ffi-button-border-color-disabled;
            box-shadow: none;
            color: @aui-ffi-button-text-color-disabled;
            text-shadow: none;
        }

        // the value
        &:after {
            color: @aui-ffi-field-text-color-disabled;
        }
    }
}

/* The clear field button */
.ffi-clear {
    @icon-box-size: @aui-icon-size-small;
    background-image: none;
    opacity: 1;
    -ms-filter: none;

    &:hover {
        opacity: 1;
        -ms-filter: none;
    }

    &::before {
        #aui.icon-pseudoelement(@aui-icon-font-family);
        color: inherit;
        content: @aui-glyph-remove;
        filter: opacity(0.5);
        font-size: @aui-icon-size-small;
        left: 0;
        line-height: 1;
        margin-top: -(@icon-box-size / 2);
        position: absolute;
        text-indent: 0;
        top: 50%;
    }

    &:hover::before {
        filter: none;
    }
}
