.aui-header {
    .aui-header-primary,
    .aui-header-secondary,
    .aui-nav {
        white-space: nowrap;
    }

    .aui-header-secondary {
        position: absolute;
        right: 10px;
    }
}
