@import (reference) './imports/global';

/*! AUI Lozenge */
.aui-lozenge {
    @aui-lozenge-border-radius: @aui-border-radius-small;

    background: var(--aui-lozenge-bg-color);
    border: 0;
    border-radius: @aui-lozenge-border-radius;
    color: var(--aui-lozenge-text-color);
    display: inline-block;
    font-size: @aui-font-size-xsmall;
    font-weight: bold;
    line-height: 1;
    margin: 0;
    padding: 2px 5px 3px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    &.aui-lozenge-subtle {
        background-color: var(--aui-lozenge-subtle-bg-color);
        color: var(--aui-lozenge-subtle-text-color);
    }
}

.aui-lozenge-success {
    background-color: var(--aui-lozenge-success-bg-color);
    color: var(--aui-lozenge-success-text-color);

    &.aui-lozenge-subtle {
        background-color: var(--aui-lozenge-success-subtle-bg-color);
        color: var(--aui-lozenge-success-subtle-text-color);
    }
}

.aui-lozenge-error, .aui-lozenge-removed {
    background-color: var(--aui-lozenge-error-bg-color);
    color: var(--aui-lozenge-error-text-color);

    &.aui-lozenge-subtle {
        background-color: var(--aui-lozenge-error-subtle-bg-color);
        color: var(--aui-lozenge-error-subtle-text-color);
    }
}

.aui-lozenge-current, .aui-lozenge-inprogress {
    background-color: var(--aui-lozenge-current-bg-color);
    color: var(--aui-lozenge-current-text-color);

    &.aui-lozenge-subtle {
        background-color: var(--aui-lozenge-current-subtle-bg-color);
        color: var(--aui-lozenge-current-subtle-text-color);
    }
}

.aui-lozenge-complete, .aui-lozenge-new {
    background-color: var(--aui-lozenge-new-bg-color);
    color: var(--aui-lozenge-new-text-color);

    &.aui-lozenge-subtle {
        background-color: var(--aui-lozenge-new-subtle-bg-color);
        color: var(--aui-lozenge-new-subtle-text-color);
    }
}
.aui-lozenge-moved {
    background-color: var(--aui-lozenge-moved-bg-color);
    color: var(--aui-lozenge-moved-text-color);

    &.aui-lozenge-subtle {
        background-color: var(--aui-lozenge-moved-subtle-bg-color);
        color: var(--aui-lozenge-moved-subtle-text-color);
    }
}
