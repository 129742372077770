@import (reference) './imports/global';

/*! AUI Inline Dialog */

aui-inline-dialog {
    --aui-inline-dialog-border-width: 0px;
    --aui-tail-deg: 0deg;
    --aui-tail-x: 0;
    --aui-tail-y: 0;
    --aui-tail-w: @aui-inline-dialog2-arrow-side-length;
    --aui-tail-bw: calc(var(--aui-inline-dialog-border-width) + @aui-inline-dialog2-arrow-border-width);

    display: block;
    position: absolute;

    // Handle FOUC
    &:not([resolved]) {
        display: none;
    }

    &.aui-layer:not([open]) {
        #aui.transition.fadeOut();
        display: block;
    }

    &.aui-layer[open] {
        #aui.transition.fadeIn();
    }

    // content
    .aui-inline-dialog-contents {
        #aui-inline-dialog.aui-inline-dialog-style();
        margin: var(--aui-tail-y) var(--aui-tail-x);
        overflow: hidden;

        & {
            #aui-inline-dialog.form-interop();
        }
    }

    // The inline dialog's "tail" is implemented using 2 pseudo-elements.
    // - `:after` is the faux tail extension of the inline dialog's background colour.
    // - `:before` adds a faux border around the tail.
    &:before,
    &:after {
        border: var(--aui-tail-w) solid transparent;
        border-bottom-color: @aui-inline-dialog-bg-color;
        box-sizing: border-box;
        content: "";
        display: inline-block;
        height: 0;
        pointer-events: none;
        position: absolute;
        width: 0;
        transform: rotate(var(--aui-tail-deg));
    }

    &:before {
        border-bottom-color: @aui-inline-dialog-border-color;
    }

    // 'top _' alignment
    &.aui-alignment-side-top,
    &.aui-alignment-side-bottom[data-popper-placement*="top"] {
        --aui-tail-deg: 180deg;
        --aui-tail-y: var(--aui-tail-w);

        &:before {
            top: calc(~"100%" - var(--aui-tail-w));
        }

        &:after {
            top: calc(~"100%" - var(--aui-tail-w) - var(--aui-tail-bw));
        }
    }

    // 'bottom _' alignment
    &.aui-alignment-side-bottom,
    &.aui-alignment-side-top[data-popper-placement*="bottom"] {
        --aui-tail-deg: 0deg;
        --aui-tail-y: var(--aui-tail-w);

        &:before {
            top: calc(~"0%" - var(--aui-tail-w));
        }
        &:after {
            top: calc(~"0%" - var(--aui-tail-w) + var(--aui-tail-bw));
        }
    }

    // 'left _' alignment
    &.aui-alignment-side-left,
    &.aui-alignment-side-right[data-popper-placement*="left"] {
        --aui-tail-deg: 90deg;
        --aui-tail-x: var(--aui-tail-w);

        &:before {
            left: calc(~"100%" - var(--aui-tail-w));
        }

        &:after {
            left: calc(~"100%" - var(--aui-tail-w) - var(--aui-tail-bw));
        }
    }

    // 'right _' alignment
    &.aui-alignment-side-right,
    &.aui-alignment-side-left[data-popper-placement*="right"] {
        --aui-tail-deg: -90deg;
        --aui-tail-x: var(--aui-tail-w);

        &:before {
            left: calc(~"0%" - var(--aui-tail-w));
        }

        &:after {
            left: calc(~"0%" - var(--aui-tail-w) + var(--aui-tail-bw));
        }
    }

    // '_ left' alignment
    &.aui-alignment-snap-left:before,
    &.aui-alignment-snap-left:after {
        left: calc(var(--aui-tail-w) + @aui-inline-dialog2-arrow-edge-offset);
    }

    // '_ center' alignment
    &.aui-alignment-snap-center:before,
    &.aui-alignment-snap-center:after {
        left: calc(~"50%" - var(--aui-tail-w));
    }

    // '_ right' alignment
    &.aui-alignment-snap-right:before,
    &.aui-alignment-snap-right:after {
        left: calc(~"100%" - ((var(--aui-tail-w) * 3) - @aui-inline-dialog2-arrow-edge-offset));
    }

    // '_ top' alignment
    &.aui-alignment-snap-top:before,
    &.aui-alignment-snap-top:after {
        top: calc(var(--aui-tail-w) + @aui-inline-dialog2-arrow-edge-offset);
    }

    // '_ middle' alignment
    &.aui-alignment-snap-middle:before,
    &.aui-alignment-snap-middle:after {
        top: calc(~"50%" - var(--aui-tail-w));
    }

    // '_ bottom' alignment
    &.aui-alignment-snap-bottom:before,
    &.aui-alignment-snap-bottom:after {
        top: calc(~"100%" - (var(--aui-tail-w) * 3) - @aui-inline-dialog2-arrow-edge-offset);
    }
}

.aui-inline-dialog {
    display: none;
    position: absolute;

    .aui-inline-dialog-contents,
    .contents {
        #aui-inline-dialog.aui-inline-dialog-style();
        overflow: hidden;

        & {
            #aui-inline-dialog.form-interop();
        }

        &.aui-inline-dialog-no-shadow {
            box-shadow: none;
        }

        &.aui-inline-dialog-auto-width {
            width: auto;
        }
    }

    /*Inline Dialog Arrow */

    .aui-inline-dialog-arrow,
    .arrow {
        position: absolute;
        height: 16px; /* for SVG positioning */
        top: -7px; /* matches #inline-dialog-shim */
        width: 16px; /* for SVG positioning */
    }

    .aui-inline-dialog-arrow.aui-css-arrow,
    .arrow.aui-css-arrow {
        width: 1px;
    }

    .aui-inline-dialog-arrow.aui-css-arrow::after,
    .arrow.aui-css-arrow::after,
    .aui-inline-dialog-arrow.aui-css-arrow::before,
    .arrow.aui-css-arrow::before {
        border-color: @aui-inline-dialog-border-color transparent;
        border-style: solid;
        border-width: 0 8px 8px;
        content: "";
        left: -8px;
        position: absolute;
        top: 0;
    }

    .aui-inline-dialog-arrow.aui-css-arrow:after,
    .arrow.aui-css-arrow:after {
        border-bottom-color: @aui-inline-dialog-bg-color;
        top: 1px;
    }

    .aui-inline-dialog-arrow.aui-css-arrow.aui-bottom-arrow:after,
    .arrow.aui-css-arrow.aui-bottom-arrow:after,
    .aui-inline-dialog-arrow.aui-css-arrow.aui-bottom-arrow:before,
    .arrow.aui-css-arrow.aui-bottom-arrow:before {
        border-width: 8px 8px 0;
    }

    .aui-inline-dialog-arrow.aui-css-arrow.aui-bottom-arrow:after,
    .arrow.aui-css-arrow.aui-bottom-arrow:after {
        border-top-color: @aui-inline-dialog-bg-color;
        top: -1px;
    }

    .aui-inline-dialog-arrow.aui-css-arrow.aui-right-arrow,
    .arrow.aui-css-arrow.aui-right-arrow {
        right: -7px;
    }

    .aui-inline-dialog-arrow.aui-css-arrow.aui-left-arrow:after,
    .arrow.aui-css-arrow.aui-left-arrow:after,
    .aui-inline-dialog-arrow.aui-css-arrow.aui-left-arrow:before,
    .arrow.aui-css-arrow.aui-left-arrow:before {
        border-width: 8px 8px 8px 0;
        border-color: transparent @aui-inline-dialog-border-color;
    }

    .aui-inline-dialog-arrow.aui-css-arrow.aui-left-arrow:after,
    .arrow.aui-css-arrow.aui-left-arrow:after {
        border-color: transparent @aui-inline-dialog-bg-color transparent transparent;
        top: 0;
        right: 0;
    }

    .aui-inline-dialog-arrow.aui-css-arrow.aui-right-arrow:after,
    .arrow.aui-css-arrow.aui-right-arrow:after,
    .aui-inline-dialog-arrow.aui-css-arrow.aui-right-arrow:before,
    .arrow.aui-css-arrow.aui-right-arrow:before {
        border-width: 8px 0 8px 8px;
        border-color: transparent @aui-inline-dialog-border-color;
        left: -7px;
    }

    .aui-inline-dialog-arrow.aui-css-arrow.aui-right-arrow:after,
    .arrow.aui-css-arrow.aui-right-arrow:after {
        border-color: transparent transparent transparent @aui-inline-dialog-bg-color;
        top: 0;
        right: 0;
        left: -8px;
    }
}

/* Shim is for lte IE9 - class is added via JS */
.inline-dialog-shim {
    border: none;
    display: block;
    height: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: 0;
    z-index: -2;
}

#inline-dialog-shim {
    display: none;
}
