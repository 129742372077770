@import (reference) './imports/global';
@import (reference) './imports/aui-theme/core/layers';

/* Skip links */
a.aui-skip-link {
    display: inline-block;
    background: var(--aui-body-background);
    #aui.shadow.z500();
    line-height: 30px;
    padding: 0 10px;
    position: absolute;
    top: -40px;
    left: 10px;
    opacity: 0;
    transition: all 300ms;

    &:focus, &:active {
        top: 10px;
        opacity: 1;
        outline-offset: -2px;
        z-index: @aui-z-skip;
    }
}
