// AUI Toolbar2
.aui-toolbar2 {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.aui-toolbar2 .aui-button {
    margin-top: 10px;
}

.aui-toolbar2:after,
.aui-toolbar2-group:after {
    clear: both;
    content: "";
    display: table;
}

.aui-toolbar2-group {
    margin: 0;
    padding: 0;
}

.aui-toolbar2-primary {
    float: left;
}

.aui-toolbar2-secondary {
    float: right;
}

.aui-toolbar2 .aui-buttons {
    float: left;
    padding: 0;
    white-space: nowrap;
}

.aui-toolbar2 .aui-button-link {
    padding: 4px 0; /* TODO: determine if this should be in buttons. 5px creates extra space at the bottom of the toolbar. */
}

.aui-toolbar2-primary > .aui-buttons,
.aui-toolbar2-secondary > .aui-buttons {
    margin: 0 10px 0 0;
}

.aui-toolbar2-secondary > .aui-buttons:last-child {
    margin-right: 0;
}
