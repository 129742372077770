@import (reference) './imports/global';

/**
 * TYPOGRAPHY
 */
html {
    #aui.text-reset();
    font-family: @aui-font-family;

    // This "font-size: 16px;" is needed for rem units from Atlaskit to work as before. Refer to:
    // https://bulldog.internal.atlassian.com/browse/JSDSS-8110
    font-size: 16px;
    > * {
        font-size: @aui-font-size-medium; // should match #aui.text-reset();
    }
}

/* International Font Stacks*/
[lang|=ja] {
    font-family: @aui-font-family-ja;
}

/* Default margins */
p,
ul,
ol,
dl,
blockquote,
pre,
form.aui,
table.aui,
.aui-tabs,
.aui-group {
    margin: @aui-grid 0 0 0;

    &:first-child {
        margin-top: 0; // No top margin to interfere with box padding
    }
}

/* Headings: desired line height in px / font size = unitless line height */
h1 {
    #aui.typography.h700();
    margin: (@aui-grid * 3) 0 0 0;
}
h2 {
    #aui.typography.h600();
    margin: (@aui-grid * 3) 0 0 0;
}
h3 {
    #aui.typography.h500();
    margin: (@aui-grid * 3) 0 0 0;
}
h4 {
    #aui.typography.h400();
    margin: (@aui-grid * 2) 0 0 0;
}
h5 {
    #aui.typography.h300();
    margin: (@aui-grid * 2) 0 0 0;
}
h6 {
    #aui.typography.h200();
    margin: (@aui-grid * 2) 0 0 0;
}
h1, h2, h3, h4, h5, h6 {
    color: @aui-text-color;

    &:first-child {
        margin-top: 0;
    }
}

/* Nice styles for using subheadings */
h1 + h2,
h2 + h3,
h3 + h4,
h4 + h5,
h5 + h6 {
    margin-top: @aui-grid;
}
/* Increase the margins on all headings when used in the group/item pattern ... */
.aui-group > .aui-item > h1:first-child,
.aui-group > .aui-item > h2:first-child,
.aui-group > .aui-item > h3:first-child,
.aui-group > .aui-item > h4:first-child,
.aui-group > .aui-item > h5:first-child,
.aui-group > .aui-item > h6:first-child {
    margin-top: (@aui-grid * 2);
}
/* ... unless they're the first-child */
.aui-group:first-child > .aui-item > h1:first-child,
.aui-group:first-child > .aui-item > h2:first-child,
.aui-group:first-child > .aui-item > h3:first-child,
.aui-group:first-child > .aui-item > h4:first-child,
.aui-group:first-child > .aui-item > h5:first-child,
.aui-group:first-child > .aui-item > h6:first-child {
    margin-top: 0;
}

/* Other typographical elements */
small {
    color: @aui-lesser-text-color;
    font-size: @aui-font-size-small;
    line-height: unit((16 / @aui-font-size-small));
}
code,
kbd {
    font-family: @aui-code-font-family;
}
var,
address,
dfn,
cite {
    font-style: italic;
}
cite:before {
    display: inline-block;
    content: "\2014";
    padding-right: 0.5em;
}
blockquote {
    border-left: 2px solid @aui-border-color;
    color: @aui-blockquote-text-color;
    margin-left: (@aui-grid * 2 - 1);
    padding: @aui-grid (@aui-grid * 2);
}
blockquote > cite {
    display: block;
    margin-top: @aui-grid;
}
q {
    color: @aui-quote-text-color;
}
q:before {
    content: open-quote;
}
q:after {
    content: close-quote;
}
abbr {
    border-bottom: 1px @aui-abbr-border-color dotted;
    cursor: help;
}
