@import (reference) './imports/global';

@aui-progress-height: 6px;
@aui-progress-indicator-border-radius: @aui-border-radius-small;

aui-progressbar {
    display: block;
}

.aui-progress-indicator {
    background: var(--aui-progressbar-track-color);
    border-radius: @aui-progress-indicator-border-radius;
    height: @aui-progress-height;
    overflow: hidden;
    position: relative;  /* so that animations work */
    width: 100%;
}

.aui-progress-indicator-value {
    animation: progressSlide 5s infinite ease-in-out;
    border-radius: @aui-progress-indicator-border-radius;
    display: block;
    height: @aui-progress-height;
    #aui.transition(none);
    position: absolute;
    width: 10%;
    background: var(--aui-progressbar-color);
}

@keyframes progressSlide {
    0% { left: 0%; }
    50% { left: 90%; }
    100% { left: 0%; }
}

.aui-progress-indicator[data-value] .aui-progress-indicator-value {
    animation: none;
    left: 0;
    background: var(--aui-progressbar-color);
    border-radius: @aui-progress-indicator-border-radius;
    #aui.transition(width 0.5s);
}

.aui-progress-indicator-static[data-value] .aui-progress-indicator-value {
    animation: none;
    left: 0;
    #aui.transition(none);
}
