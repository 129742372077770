@import (reference) './imports/global';

@aui-restfultable-row-focused-horizontal-border-width: 2px;
@aui-restfultable-row-focused-border-color: var(--aui-restfultable-row-focused-border-color);
@aui-restfultable-row-create-border: var(--aui-restfultable-row-create-border-color);
@aui-restfultable-row-editable-hover-bg-color: var(--aui-restfultable-row-editable-hover-bg-color);
@aui-restfultable-error-text-color: var(--aui-restfultable-error-text-color);
@aui-restfultable-header-row-bg-color: var(--aui-restfultable-header-row-bg-color);
@aui-restfultable-header-row-text-color: var(--aui-restfultable-header-row-text-color);
@aui-restfultable-row-moving-bg-color: var(--aui-restfultable-row-moving-bg-color);
@aui-restfultable-editable-em-text-color: var(--aui-restfultable-editable-em-text-color);
@aui-restfultable-row-active-bg-color: var(--aui-restfultable-row-active-bg-color);
@aui-restfultable-row-hover-bg-color: var(--aui-restfultable-row-hover-bg-color);

/* ----- table loading styles ----- */
.aui-restfultable-init {
    text-align: center;
    vertical-align: middle;
    padding: 20px;
}

.aui-restfultable-init .aui-restfultable-loading {
    display: flex;
    justify-content: center;
    aui-spinner {
        margin-right: 8px;
    }
}

/* ----- Some basics ----- */
table.aui.aui-restfultable > thead > tr > th {
    background-color: @aui-restfultable-header-row-bg-color;
    color: @aui-restfultable-header-row-text-color;
}

.aui-restfultable .aui-restfultable-status {
    width: 1px;
    white-space: nowrap;
}

/* ----- Generic row - All states ----- */
.aui-restfultable-row td {
    vertical-align: top;
}

.aui-restfultable-row .aui-restfultable-order {
    width: 8px;
}

.aui-restfultable-row .aui-restfultable-operations {
    width: 160px;
    white-space: nowrap;
}

.aui-restfultable-row .aui-restfultable-operations input.button,
.aui-restfultable .aui-restfultable-row select {
    margin-top: 2px;
}

.aui-restfultable .aui-restfultable-row input.text,
.aui-restfultable .aui-restfultable-row select {
    box-sizing: border-box;
    margin: 0;
    max-width: none;
    width: 100%;
}

.aui-restfultable .aui-restfultable-order {
    width: 8px;
}

.aui-restfultable .aui-restfultable-row .aui-restfultable-draghandle {
    display: inline-block;
    width: 8px;
    min-height: 24px;
    margin-bottom: -6px;
    background-image: url('images/bg-grippy.png');
    cursor: move;
}

/* ----- Create row ----- */
.aui-restfultable-create td {
    border: solid @aui-restfultable-row-create-border;
    border-width: 1px 0;
}

.aui-restfultable .aui-restfultable-create .aui-restfultable-draghandle {
    display: none;
}

/* ----- Focused row ----- */
.aui-restfultable-focused:not(.aui-restfultable-disabled) {
    border-color: @aui-restfultable-row-focused-border-color;
    border-style: solid;
    border-width: @aui-restfultable-row-focused-horizontal-border-width 0;
}

/* ----- Disabled row ----- */
.aui-restfultable-row.aui-restfultable-disabled td {
    opacity: 0.5;
}

/* ----- Readonly row ----- */

/* https://ecosystem.atlassian.net/browse/AUI-1940
 * Because the table cells have fractional widths in IE, which then rounds up/down at random when calling clientWidth
 * which means the helper is too narrow to contain the children.
 */
.aui-restfultable-readonly.ui-sortable-helper {
   white-space: nowrap;
}

.aui-restfultable-readonly.aui-restfultable-movable > div {
    background-color: @aui-restfultable-row-moving-bg-color !important;
    box-sizing: border-box;
    display: table-cell;
    height: 100%;
    min-height: 40px; /* the average height of a row in a restful table. The actual height should be set on the container */
    padding: 7px 10px;
    vertical-align: middle;
}

.aui-restfultable-readonly > .aui-restfultable-movable {
    height: 100%;
}

.aui-restfultable-readonly .aui-restfultable-editable-no-value .aui-restfultable-editable {
    visibility: hidden;
}

.aui-restfultable-readonly .aui-restfultable-operations a {
    visibility: visible;
}

.aui-restfultable-readonly .aui-restfultable-editable {
    display: block;
    box-sizing: border-box;
    margin: 0;
    padding: 3px 24px 2px 4px;
    position: relative;
}

.aui-restfultable-readonly .aui-restfultable-editable .aui-iconfont-edit,
.aui-restfultable-readonly .aui-restfultable-editable .icon-edit-sml {
    margin: 0;
    position: absolute;
    right: 4px;
    top: 4px;
    visibility: hidden;
}

.aui-restfultable-allowhover td .aui-restfultable-editable .aui-iconfont-edit,
.aui-restfultable-allowhover td .aui-restfultable-editable .icon-edit-sml {
    visibility: hidden;
}

.aui-restfultable-allowhover .aui-restfultable-readonly td:hover .aui-restfultable-editable .aui-iconfont-edit,
.aui-restfultable-allowhover .aui-restfultable-readonly td:hover .aui-restfultable-editable .icon-edit-sml {
    visibility: visible;
}

.aui-restfultable-readonly .aui-restfultable-editable em {
    color: @aui-restfultable-editable-em-text-color; // doubt this is still used
}

/* ----- Hover row ----- */
.aui-restfultable-row.aui-restfultable-active {
    background-color: @aui-restfultable-row-active-bg-color;
}

.aui-restfultable-allowhover tr:hover td {
    background-color: @aui-restfultable-row-hover-bg-color;
}

.aui-restfultable-allowhover td:hover .aui-restfultable-editable {
    visibility: visible;
    background: @aui-restfultable-row-editable-hover-bg-color;
    cursor: pointer;
}

/* ----- Validation ----- */
.aui-restfultable .error {
    clear: both;
    color: @aui-restfultable-error-text-color;
    display: block;
    margin: 5px 0 0 0;
}
