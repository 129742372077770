@import (reference) './imports/global';
@import (reference) './imports/aui-theme/components/dropdown';
@import (reference) './imports/aui-theme/components/forms';
@import (reference) './imports/mixins/icon-pseudoelement';
@import (reference) './imports/mixins/focus';
@import (reference) './imports/aui-theme/core/colors';

/*
    ADG styles to be added to select2 items.

    There are 5 main components that select2 uses.
    # select2-container - the top-level container that wraps the original <input> element
    ## select2-choice - the <a> the user clicks on to trigger the dropdown, shows the selected item
    # select2-drop - the top-level container directly in the <body> that displays the dropdown
    ## select2-search - the search box in the dropdown
    ## select2-results - the list of results in the dropdown, including groups

    Overrides in this CSS file should start with:
    # .aui-select2-container - in auiSelect2 JS we add this class to the top container
    # .aui-select2-drop - in auiSelect2 JS we add this class to the dropdown container

    By sticking to these two selector elements we won't accidentally break other code that uses select2. If they want
    sexy ADG styles they can call auiSelect2() instead of select2().
    Please don't add application-specific styles/rules in here, as these styles will eventually be pulled into AUI.
*/

@aui-select2-form-field-border-radius: @aui-border-radius-smallish;
@aui-select2-chosen-border-radius: @aui-border-radius-smallish;
@aui-select2-chosen-close-button-length: 17px;

.select2-drop-mask {
    z-index: @aui-z-select2 - 2;
}

.aui-select2-drop {
    --aui-item-border-radius: 0;
    margin-top: @aui-dropdown-trigger-offset;
    margin-bottom: 0;
    z-index: @aui-z-select2 - 1; // override select2 z-indices to fit in AUI's ranges. sits above blanket but below suggestions.
}

.aui-select2 .select2-search {
    z-index: @aui-z-select2;
}

.aui-select2-drop .select2-result-selectable .select2-match,
.aui-select2-drop .select2-result-unselectable .select2-match {
    text-decoration: none;
    font-weight: bold;
}

.aui-select2-drop .select2-results ul.select2-result-sub {
    padding: 0;
}

@aui-select2-search-input-padding: 5px;
@aui-dropdown2-padding: 3px;
.aui-select2-drop.aui-dropdown2 .select2-search {
    padding: @aui-select2-search-input-padding+@aui-dropdown2-padding;
}

.aui-select2-drop .select2-results ul.select2-result-sub > li .select2-result-label,
.aui-select2-drop .select2-results .select2-result-label,
.aui-select2-drop .select2-results .select2-searching,
.aui-select2-drop .select2-results .select2-no-results,
.aui-select2-drop .select2-results .select2-more-results {
    #aui-nav.item-base();
}

.aui-select2-drop .select2-result-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: inherit; // this will be set by the dropdown item style
}

.aui-select2-drop .select2-results {
    margin: 0;
    padding: 0;
}

.aui-select2-drop .select2-more-results,
.aui-select2-drop .select2-searching,
.aui-select2-drop .select2-no-results {
    background-color: transparent;
}

.aui-select2-drop {
    border: @aui-form-field-border-width solid @aui-button-border-color;
}

.select2-drop.select2-drop-above .select2-search input {
    margin-top: 0;
}

.aui-select2-drop .select2-search {
    display: flex;
    align-items: center;
    @icon-offset: 12px;
    #aui.icon(@aui-glyph-search, {
        left: auto;
        right: @icon-offset;
    });
    input {
        //padding for the icon
        padding-right: (@icon-offset/2) + @aui-icon-size-small;
        #aui.with-focus-border();
        border-radius: @aui-select2-form-field-border-radius;
        //we need to use important because jquery plugin uses important as well :(
        background: none !important;
        color: inherit;
        font-family: inherit;
        min-height: 0;
    }
}

.aui-select2-container.select2-container .select2-choice,
.aui-select2-drop {
    color: inherit;
    max-width: none;
}

.aui-select2-drop.aui-dropdown2 {
    #aui-dropdowns.aui-dropdown-style();

    // Styles for when the results are shown above instead of below the input
    &.select2-drop-above {
        margin-bottom: @aui-dropdown-trigger-offset;
        margin-top: 0;
    }
}
.aui-select2-drop.aui-dropdown2.select2-with-searchbox {
    padding-top: 0;
}

.aui-select2-drop .select2-results .select2-result-selectable {
    #aui-nav.item-style(normal);
}

.aui-select2-drop .select2-results .select2-highlighted {
    #aui-nav.item-style(hover);
}

.aui-select2-drop .select2-results .select2-result-unselectable {
    #aui-nav.item-style(disabled);
}

// beat the previous rule and ensure the cursor is correct.
.aui-select2-drop .select2-results .select2-result-with-children {
    cursor: inherit;
}

// Add dividers between grouped options
.aui-select2-drop .select2-results {
    .select2-result-with-children {
        // We need to add both borders because the results double
        // as group containers and we don't know where in
        // the source order they'll appear.
        border-top: 1px solid @aui-dropdown-border-color;
        border-bottom: 1px solid @aui-dropdown-border-color;
        margin: @aui-dropdown-group-spacing 0;
        padding: 0;

        &:first-child {
            border-top: 0;
            margin-top: 0;
        }

        &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
        }

        > :first-child {
            margin-top: @aui-dropdown-group-spacing;
        }

        > :last-child {
            margin-bottom: @aui-dropdown-group-spacing;
        }

        > .select2-result-label {
            #aui.typography.h100(@aui-dropdown-heading-text-color);
        }
    }

    // We now selectively remove borders and add margins, depending
    // on subsequent elements after a group of results.
    // This occurs when an <optgroup> is sandwiched
    // in between <option> elements.
    .select2-result-with-children + .select2-result > .select2-result-label {
        margin-top: @aui-dropdown-group-spacing;
    }

    .select2-result-with-children + .select2-result-with-children {
        border-top: 0;
    }
}

.aui-select2-container.select2-container .select2-choice > span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.aui-select2-container.select2-container .select2-choice .select2-arrow {
    background: none;
    border: none;
    filter: none;
}

.aui-select2-container.select2-container-active .select2-choice,
.aui-select2-container.select2-container-active .select2-choices {
    border: none;
    outline: none;
    box-shadow: none;
}

.aui-select2-container.select2-container .select2-choice .select2-arrow b {
    display: none;
    background: none;
}

.aui-select2-container.select2-container .select2-choice {
    padding-right: 23px; /* 8px (arrow width) + 10px (right padding) + 5px (margin between arrow and logo) */
    position: relative;
    width: inherit;
}

.aui-select2-container.select2-container a.select2-choice,
.aui-select2-container.select2-container a.select2-choice:focus,
.aui-select2-container.select2-container a.select2-choice:hover,
.aui-select2-container.select2-container a.select2-choice:active {
    text-decoration: none;
}

.aui-select2-container.select2-container .select2-choice:after {
    #aui.icon-pseudoelement();
    content: @aui-glyph-chevron-down;
    height: 0;
    margin-left: -18px;
    margin-top: -2px;
    opacity: 1;
    position: absolute;
    top: calc(50% - 10px);
    width: 0;
    font-size: 16px;
    line-height: 24px;
    right: 22px;
}

/* Set the default text color to inherit.
   NOTE: this class is only marked as !important because Select2 marks the same
   selector as !important in it's styles. */
.aui-select2-container .select2-input {
    color: inherit !important;
}

/* Since select2 uses the placeholder text as the value of the input,
  the following style is used to override text color to mach ADG placeholder.
  Select2 gives the input a class of select2-default when the value represents the placeholder value.
  NOTE: this class is only marked as !important because Select2 marks the same
  selector as !important in its styles. */
.aui-select2-container .select2-default {
    color: var(--aui-select2-placeholder-text-color) !important;
}

.aui-select2-container .select2-choices .select2-search-field input {
    font-family: inherit;
    font-size: 14px;
    height: 1.4285714285714em; /* 20px - using ems so the fields increase in line with user specified font-sizes */
    line-height: 1.4285714285714;
    margin: 0;
    padding: 0;
}

.aui-select2-container.select2-container-multi .select2-choices {
    #aui-forms.aui-select2-input-field-style(normal);
    #aui.icon(@aui-glyph-chevron-down, {
        font-size: 16.5px;
        left: inherit;
        color: var(--aui-button-default-text-color);
        right: 3px;
        top: 50%;
        line-height: 16px;
    });

    min-height: 0;
}

.aui-select2-container.select2-container-multi.select2-container  .select2-choices.select2-choices{
    padding-right: @aui-icon-size-small;
}
.aui-select2-container.select2-container-multi.text {
    height: auto;
}


// We need to be that specific with allowClear to change the default styles when the option is set to "true"
.aui-select2-container, .aui-select2-container.select2-allowclear {
    .select2-search-choice-close {
        // Remove default select2 close icon image
        background-image: none !important; // because the base select2 styles' media queries for retina use !important :(
        left: unset; // from default select2 styles

        // Apply our styles
        border-radius: @aui-select2-chosen-border-radius;
        color: inherit;
        cursor: pointer;
        text-align: center;
        width: @aui-select2-chosen-close-button-length;
        height: auto; // Needed to override select2 close button height
        text-decoration: none;
        filter: opacity(0.5);

        // Apply our own icon
        &::before {
            #aui.icon-pseudoelement(@aui-icon-font-family);
            content: @aui-glyph-close;
            font-size: 10px;
            margin: auto;
        }

        &:hover {
            background-color: var(--aui-label-close-hover-bg-color);
            color: var(--aui-label-close-hover-text-color);
            filter: none;
        }
    }

    // set specific styles for "close" icon when is shown inside a multi-select option that is selected
    .select2-search-choice .select2-search-choice-close {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
    }
}

.select2-search-choice {
    .aui-select2-container .select2-choices & {
        background: var(--aui-select2-chosen-bg-color);
        border: 1px solid $background;
        border-radius: @aui-select2-chosen-border-radius;
        box-shadow: none;
        color: var(--aui-select2-chosen-text-color);
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
        line-height: 1;
        padding: 1px 18px 1px 5px;
        position: relative;
        margin: 1px 5px 1px 0;
        text-align: left;
        text-decoration: none;
    }

    .aui-select2-container .select2-choices:hover & {
        background-color: var(--aui-select2-chosen-hover-bg-color);
        border-color: $background-color;
        color: var(--aui-select2-chosen-hover-text-color);
    }

    .aui-select2-container.select2-container-active .select2-choices & {
        background-color: var(--aui-select2-active-chosen-bg-color);
        border-color: $background-color;
        color: var(--aui-select2-active-chosen-text-color);
    }

    .aui-select2-container .select2-choices &.select2-search-choice-focus {
        #aui.with-focus-ring();
        background-color: var(--aui-select2-chosen-hover-bg-color);

        .select2-search-choice-close {
            &:extend(.aui-select2-container .select2-search-choice-close:hover);
        }
    }
}

/* In order to beat out select2's overrides, the aui-button style had to be copied in below.
   Unfortunately, any changes to that style will have to be copied here as well to keep the select2
   element consistent.*/
.aui-select2-container.select2-container .select2-choice,
.aui-select2-container.select2-container a.select2-choice,
.aui-select2-container.select2-container .select2-choice:visited {
    #aui.box-sizing();
    #aui-buttons.aui-button-base();
    #aui-buttons.aui-button-style(normal);
    border-width: 0;
    display: inline-block;
    filter: none;
    height: 2.1428571428571em; /* 30px - using ems so the fields increase in line with user specified font-sizes */
    line-height: 1.4285714285714;
    margin: 0;
    padding: 4px 10px;
    text-decoration: none;
    text-shadow: none;
    vertical-align: top;
    white-space: nowrap;
}

// Dropdown hovered by mouse
.aui-select2-container:not(.select2-dropdown-open).select2-container .select2-choice:hover {
    #aui-buttons.aui-button-style(hover);
    box-shadow: none;
    text-decoration: none;
}

// Dropdown focussed by keyboard
.aui-select2-container.select2-container.select2-container-active .select2-choice,
.aui-select2-container.select2-container .select2-choice:active {
    #aui-buttons.aui-button-style(hover);
}

.aui-select2-container.select2-container:not(.select2-dropdown-open).select2-container-active .select2-choice,
.aui-select2-container.select2-container:not(.select2-dropdown-open) .select2-choice:active {
    #aui.with-focus-ring();
}

// Dropdown "selected" (i.e., active with an open dropdown)
.aui-select2-container.select2-container.select2-dropdown-open:not(.select2-container-multi) .select2-choice,
.aui-select2-container.select2-container.select2-dropdown-open:not(.select2-container-multi) .select2-choices {
    #aui-buttons.aui-button-style(selected);
}

.aui-select2-container.select2-container.select2-drop-above .select2-choice,
.aui-select2-container.select2-dropdown-open.select2-drop-above .select2-choice,
.aui-select2-container.select2-dropdown-open.select2-drop-above .select2-choices,
.aui-select2-container.select2-container-multi .select2-choices .select2-search-choice {
    filter: none;
}

.aui-select2-container .aui-avatar {
    margin-right: 5px;
}

.aui-select2-container.aui-has-avatar .select2-choices .select2-search-field input {
    padding: 1px 0;
}

.aui-select2-drop.aui-has-avatar .select2-results .select2-result-label .aui-avatar {
    margin-right: 5px;
    vertical-align: middle;
}

.aui-select2-drop.aui-has-avatar .select2-more-results,
.aui-select2-drop.aui-has-avatar .select2-searching,
.aui-select2-drop.aui-has-avatar .select2-no-results {
    padding-bottom: 5px;
    padding-top: 5px;
}
