@import (reference) 'core-variables';
@import (reference) 'component-variables';

:root {
    #aui-themes.light();
}

// Those selectors rely on how Design Tokens library stores information about currently activated theme:
@theme-preference-not-set: ~'html:not([data-theme])';
@light-mode-enabled: ~'[data-color-mode="light"]';
@dark-mode-enabled: ~'[data-color-mode="dark"]';
@original-light-theme-enabled: ~'html@{light-mode-enabled}[data-theme~="light:original"]';
@original-dark-theme-enabled: ~'html@{dark-mode-enabled}[data-theme~="dark:original"]';
@original-mode-enabled: ~'@{theme-preference-not-set}, @{original-light-theme-enabled}, @{original-dark-theme-enabled}';
// Original is used as basis because it's just easier to understand
@design-tokens-mode-enabled: ~'html:not(@{original-mode-enabled})';
@design-tokens-light-theme-enabled: ~'@{design-tokens-mode-enabled}@{light-mode-enabled}';
@design-tokens-dark-theme-enabled: ~'@{design-tokens-mode-enabled}@{dark-mode-enabled}';


@{design-tokens-mode-enabled} {
    #aui-themes.design-tokens();
};

#aui-themes {

    // The mixins are intended to apply certain styles only when the light or
    // dark color mode is on.  They support both the classic approach to the
    // themes and the new recommended design-tokens one.  `data-color-mode`
    // corresponds to the value set by `setGlobalTheme` in
    // atlassian-frontend/packages/design-system/tokens
    .when-design-tokens(@rules) {
        @{design-tokens-mode-enabled} & {
            @rules();
        }
    }

    .when-light(@rules) {
        & {
            @rules();
        }

        @{design-tokens-light-theme-enabled} & {
            @rules();
        }
    }

    .when-dark(@rules) {
        body.aui-theme-dark & {
            @rules();
        }

        @{design-tokens-dark-theme-enabled} & {
            @rules();
        }
    }

    .switch-light-dark(@lightRules; @darkRules) {
        #aui-themes.when-light(@lightRules);
        #aui-themes.when-dark(@darkRules);
    }
}
