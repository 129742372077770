@import (reference) '../theme';

@aui-form-placeholder-text-color: var(--aui-form-placeholder-text-color);
@aui-form-placeholder-disabled-text-color: var(--aui-form-placeholder-disabled-text-color);
@aui-form-label-text-color: var(--aui-form-label-text-color);
@aui-form-error-text-color: var(--aui-form-error-text-color);
@aui-form-description-text-color: var(--aui-form-description-text-color);
@aui-form-disabled-field-bg-color: var(--aui-form-disabled-field-bg-color);
@aui-form-disabled-field-text-color: var(--aui-form-disabled-field-text-color);
@aui-form-disabled-field-label-color: var(--aui-form-disabled-field-label-color);
@aui-form-field-border-color: var(--aui-form-field-border-color);
@aui-form-field-hover-border-color: var(--aui-form-field-hover-border-color);
@aui-form-field-default-text-color: var(--aui-form-field-default-text-color);
@aui-form-field-default-bg-color: var(--aui-form-field-default-bg-color);
@aui-form-field-hover-text-color: var(--aui-form-field-hover-text-color);
@aui-form-field-hover-bg-color: var(--aui-form-field-hover-bg-color);
@aui-form-field-focus-bg-color: var(--aui-form-field-focus-bg-color);
@aui-form-select-bg-color: var(--aui-form-select-bg-color);
@aui-form-select-border-color: var(--aui-form-select-border-color);
@aui-form-select-hover-bg-color: var(--aui-form-select-hover-bg-color);
@aui-form-checkbox-radio-active-bg-color: var(--aui-form-checkbox-radio-active-bg-color);
@aui-form-optgroup-text-color: var(--aui-form-optgroup-text-color);
@aui-form-optgroup-bg-color: var(--aui-form-optgroup-bg-color);
@aui-form-option-bg-color: var(--aui-form-option-bg-color);
@aui-form-pre-bg-color: var(--aui-form-pre-bg-color);

@aui-form-field-border-radius: @aui-border-radius-smallish;
@aui-form-field-border-width: 2px;

@aui-form-field-width-default: 250px;
@aui-form-field-width-short: 75px;
@aui-form-field-width-medium: 165px;
@aui-form-field-width-medium-long: 350px;
@aui-form-field-width-long: 500px;
@aui-form-field-width-full: none;
@aui-form-field-height: unit((30px / @aui-font-size-medium), em); // 30px - using ems so the fields increase in line with user specified font-sizes

@aui-form-transition-time: 0.2s;

@aui-form-button-size: 10px;
@aui-form-button-border-width: 2px;
@aui-form-button-focus-border-width: 2px;
@aui-form-radio-checkbox-outline-padding-space: 2px;

#aui-forms {
    .aui-input-field-style(normal) {
        background-color: @aui-form-field-default-bg-color;
        color: @aui-form-field-default-text-color;
    }

    .aui-input-field-style(hover) {
        background-color: @aui-form-field-hover-bg-color;
        border-color: @aui-form-field-hover-border-color;
        color: @aui-form-field-hover-text-color;
    }

    .aui-input-field-style(focus) {
        background-color: @aui-form-field-focus-bg-color;
        color: @aui-text-color;
    }

    .aui-select2-input-field-style(normal) {
        background-color: var(--aui-select2-field-default-bg-color);
        color: @aui-form-field-default-text-color;
    }

    .aui-select2-input-field-style(hover) {
        background-color: var(--aui-select2-field-hover-bg-color);
        border-color: @aui-form-field-hover-border-color;
        color: @aui-form-field-hover-text-color;
    }

    .aui-radio-checkbox-size() {
        display: inline-block;
        width: @aui-form-button-size;
        height: @aui-form-button-size;
    }

    .aui-radio-checkbox-outline-size() {
        display: inline-block;
        width: @aui-form-button-size + @aui-form-button-border-width * 2 + (@aui-form-radio-checkbox-outline-padding-space * 2);
        height: @aui-form-button-size + @aui-form-button-border-width * 2 + (@aui-form-radio-checkbox-outline-padding-space * 2);
    }
}

#aui-legacy-forms {
    // Define legacy colours
    .colors() {
        @import (reference) '../adg/adg-colors';
        @import (reference) '../adg/adg-neutral-light';
        @aui-legacy-form-field-default-bg-color: @ak-color-N0;
        @aui-legacy-form-field-default-text-color: @ak-color-N800;
        @aui-legacy-form-field-border-width: 1px;
        @aui-legacy-form-field-border-color: @ak-color-N50;
        @aui-legacy-form-label-text-color: @ak-color-N200;
        @aui-legacy-form-placeholder-text-color: @ak-color-N80;
        @aui-legacy-form-disabled-field-bg-color: @ak-color-N20;
        @aui-legacy-form-disabled-field-text-color: @ak-color-N80;
    }
    // Make the colours available to the other mixins here
    .colors();

    .aui-input-field-style(normal) {
        background-color: @aui-legacy-form-field-default-bg-color;
        color: @aui-legacy-form-field-default-text-color;
    }

    .aui-input-field-style(hover) {
        background-color: @aui-form-field-hover-bg-color;
        border-color: @aui-form-field-hover-border-color;
        color: @aui-form-field-hover-text-color;
    }

    .aui-input-field-style(focus) {
        background-color: @aui-form-field-focus-bg-color;
        color: @aui-text-color;
    }

    .aui-radio-checkbox-size() {
        display: inline-block;
        width: @aui-form-button-size;
        height: @aui-form-button-size;
    }
}
