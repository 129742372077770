@import (reference) './imports/global';

// The purpose of this file is to restore some of the old ADG2 icons that are missing in ADG Server

// The old ADG2 icons that don't have a proper substitution in ADGS icons set
.aui-iconfont-autocomplete-date,
.aui-iconfont-blogroll,
.aui-iconfont-devtools-browse-up,
.aui-iconfont-deploy,
.aui-iconfont-custom-bullet,
.aui-iconfont-hipchat,
.aui-iconfont-file-code,
.aui-iconfont-file-doc,
.aui-iconfont-file-java,
.aui-iconfont-file-pdf,
.aui-iconfont-file-ppt,
.aui-iconfont-file-txt,
.aui-iconfont-file-wav,
.aui-iconfont-file-xls,
.aui-iconfont-file-zip,
.aui-iconfont-page-blogpost,
.aui-iconfont-page-template,
.aui-iconfont-macro-default,
.aui-iconfont-macro-gallery,
.aui-iconfont-macro-status,
.aui-iconfont-user-status,
.aui-iconfont-view-card,
.aui-iconfont-workbox-empty,
.aui-iconfont-export-list,
.aui-iconfont-admin-fusion,
.aui-iconfont-admin-jira-fields,
.aui-iconfont-admin-issue,
.aui-iconfont-admin-notifications,
.aui-iconfont-admin-jira-screens,
.aui-iconfont-priority-highest,
.aui-iconfont-priority-high,
.aui-iconfont-priority-medium,
.aui-iconfont-share-list,
.aui-iconfont-version,
.aui-iconfont-workflow,
.aui-iconfont-admin-jira-settings,
.aui-iconfont-reopen,
.aui-iconfont-roadmap,
.aui-iconfont-deploy-success,
.aui-iconfont-deploy-fail,
.aui-iconfont-file-generic,
.aui-iconfont-file-video,
.aui-iconfont-blogroll-large,
.aui-iconfont-priority-low,
.aui-iconfont-priority-lowest,
.aui-iconfont-review,
.aui-iconfont-rss,
.aui-iconfont-sourcetree,
.aui-iconfont-space-default,
.aui-iconfont-table-bg,
.aui-iconfont-table-no-bg,
.aui-iconfont-devtools-folder-open,
.aui-iconfont-devtools-compare,
.aui-iconfont-devtools-file,
.aui-iconfont-devtools-file-binary,
.aui-iconfont-devtools-file-commented,
.aui-iconfont-devtools-repository,
.aui-iconfont-devtools-repository-forked,
.aui-iconfont-devtools-repository-locked,
.aui-iconfont-devtools-side-diff,

// Table Cut, copy, paste, row and column are reverted to ADG 2
.aui-iconfont-table-cut-row,
.aui-iconfont-table-copy-row,
.aui-iconfont-table-paste-row,
.aui-iconfont-table-cut-column,
.aui-iconfont-table-copy-column,
.aui-iconfont-table-paste-column,

.aui-iconfont-maximise-small,
.aui-iconfont-minimise-small,
.aui-iconfont-more-small,
.aui-iconfont-move-small,
.aui-iconfont-version-unreleased,
.aui-iconfont-puzzle,
.aui-iconfont-editor-files,
.aui-iconfont-bp-sprint,
.aui-iconfont-jira-issues,
.aui-iconfont-bitbucket,
.aui-iconfont-jira-completed-task,
.aui-iconfont-jira-test-session,
.aui-iconfont-back-page,
.aui-iconfont-macro-code {
    --aui-icon-font: @aui-old-icon-font-family;
}
