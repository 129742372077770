@import (reference) './imports/global';
@import (reference) './imports/mixins';
@import (reference) './imports/aui-theme/components/forms';

/* Field styles */
form.aui:not(.aui-legacy-forms) {
    ::-webkit-contacts-auto-fill-button {
        background: @aui-form-field-default-text-color;
    }
    .text,
    .password,
    .textarea,
    .select {
        #aui-forms.aui-input-field-style(normal);
        font-size: @aui-font-size-medium;
        font-family: inherit;

        &:hover {
            #aui-forms.aui-input-field-style(hover);
        }

        &:-webkit-autofill {
            -webkit-text-fill-color: var(--aui-form-field-autofilled-text-color);
            // for some reason, setting background-color doesn't work. webkit does some weird shit.
            -webkit-box-shadow: 0 0 0 1000px var(--aui-form-field-autofilled-bg-color) inset;
            color: var(--aui-form-field-autofilled-text-color);
            border-color: var(--aui-form-field-autofilled-border-color);

            &::-webkit-contacts-auto-fill-button {
                background: var(--aui-form-field-autofilled-text-color);
            }

        }
    }

    .multi-select {
        #aui-forms.aui-select2-input-field-style(normal);
        font-size: @aui-font-size-medium;
        font-family: inherit;

        &:hover {
            #aui-forms.aui-select2-input-field-style(hover);
        }
    }

    .select,
    .multi-select {
        &:hover {
            background-color: @aui-form-select-hover-bg-color;
            border-color: @aui-form-select-hover-bg-color;
        }
    }

    .aui-select2-container {
        & > ul:hover {
            #aui-forms.aui-select2-input-field-style(hover);
        }

        &.select2-container-active > ul {
            #aui-forms.aui-input-field-style(focus);
        }
    }

    .multi-select {
        &:hover > option {
            #aui-forms.aui-select2-input-field-style(hover);
        }
    }

    .text,
    .password,
    .textarea,
    .select {
        #aui.transition(background-color @aui-form-transition-time ease-in-out);
        #aui.transition(border-color @aui-form-transition-time ease-in-out);
        border: @aui-form-field-border-width solid @aui-form-field-border-color;
        border-radius: @aui-form-field-border-radius;
        box-sizing: border-box;
        font-size: inherit;
        margin: 0;
        vertical-align: baseline;
    }

    .multi-select,
    .aui-select2-container .select2-choices {
        #aui.transition(background-color @aui-form-transition-time ease-in-out);
        #aui.transition(border-color @aui-form-transition-time ease-in-out);
        border: @aui-form-field-border-width solid var(--aui-select2-field-border-color);
        border-radius: @aui-form-field-border-radius;
        box-sizing: border-box;
        font-size: inherit;
        margin: 0;
        vertical-align: baseline;
        background-image: none;
    }

    .multi-select {
        background-color: @aui-form-select-bg-color;
        border-color: @aui-form-select-border-color;
    }

    .text,
    .password,
    .select,
    .aui-select2-container .select2-choices {
        height: @aui-form-field-height;
        line-height: 1.4285714285714;
        padding: 3px 4px;
    }

    .textarea,
    .select[size],
    .multi-select {
        height: auto;
        line-height: 1.4285714285714;
        margin: 0;
        padding: 3px 4px;
    }

    .textarea {
        overflow-y: auto;
    }

    .multi-select {
        margin-top: 2px;
    }
    .group .field-group,
    .date-select .field-group {
        clear: none;
        padding-left: 0;
        padding-top: 0;
    }

    /* Focus styles */
    .text,
    .password,
    .textarea,
    .select,
    .multi-select {
        &:focus {
            #aui-forms.aui-input-field-style(focus);
        }
        &:focus > option {
            #aui-forms.aui-input-field-style(focus);
        }
    }
    .text,
    .password,
    .textarea,
    .select,
    .multi-select,
    .aui-select2-container .select2-choices {
        // Forms get a custom "border-as-focus" style, because:
        // a) the form field doesn't "grow" in size,
        // b) the "focus ring" is impossible to clip when it's a border, and
        // c) it's more in line with how Atlaskit does it.
        &:focus {
            #aui.with-focus-border(@aui-focus-ring-color, @aui-form-field-border-width);
        }
    }

    .aui-select2-container.select2-container-active .select2-choices {
        #aui.with-focus-border();
    }

    .button,
    .cancel {
        #aui.focus(#aui.with-focus-ring());
    }

    /* Placeholder form element styles */

    .text, .password, .textarea {
        #aui.placeholder(@aui-form-placeholder-text-color);

        &[disabled] {
            #aui.placeholder(@aui-form-placeholder-disabled-text-color);
        }
    }

    /* Disabled form element styles */

    .text[disabled],
    .password[disabled],
    .textarea[disabled],
    .select[disabled],
    .multi-select[disabled],
    .select[disabled] option,
    .select[disabled] optgroup,
    .multi-select[disabled] option,
    .multi-select[disabled] optgroup {
        background-color: @aui-form-disabled-field-bg-color;
        color: @aui-form-disabled-field-text-color;
        border: none;
    }
    .text[disabled],
    .password[disabled],
    .textarea[disabled],
    .select[disabled],
    .multi-select[disabled] {
        border: 2px solid transparent;
    }

    .select {
        margin-top: 1px;
        vertical-align: top;
        background-color: @aui-form-select-bg-color;
        border-color: @aui-form-select-border-color;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &::-ms-expand {
            display: none;
        }
    }
    /* Ensure AUI Select2's do not show as regular text fields */
    .aui-select2-container {
        border: 0;
        height: auto;
        padding: 0;
        vertical-align: baseline;
        width: 100%;
    }

    .aui-select2-container .select2-choices {
        height: auto;
        max-width: none;
    }

    .field-group,
    .group,
    .date-select {
        box-sizing: border-box;
        clear: both;
        padding: 4px 0 4px 145px;
        position: relative;
        margin: 1px 0;
        width: 100%;
    }

    .group {
        padding-top: 0;
    }

    // both are @ak-color-N600
    @chevron-dark:   url('data:image/svg+xml,%3Csvg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill="%23A5ADBA" d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z"/%3E%3C/svg%3E%0A');
    @chevron-light:  url('data:image/svg+xml,%3Csvg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill="%23344563" d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z"/%3E%3C/svg%3E%0A');

    select[disabled]:not([size]),
    .select:not([size]) {
        background-repeat: no-repeat;
        background-position: calc(100% + 1.5px) 50%;
        background-size: 25px;
    }

    select.select[disabled]:not([size]) {
        // used to be a light ak-color-N70, but there were only 2-3 shades of difference
        // between that and dark ak-color-N600, so I'm re-using it here.
        background-image: @chevron-dark;
    }

    select.select:not([size]) {
        padding-right: 20px;
        background-image: @chevron-light;
    }

    #aui-themes.when-dark({
        select.select:not([size]) {
            background-image: @chevron-dark;
        }
    });

    .icon-required {
        left: 100%;
        position: absolute;
        top: 7px;

        &::before {
            content: "*";
            position: absolute;
            left: 0;
            top: 0;
            text-indent: initial;
            color: @aui-form-error-text-color;
            line-height: 1;
            font-size: @aui-font-size-small;
        }
    }

    legend,
    label {
        color: @aui-form-label-text-color;
        font-weight: @aui-font-weight-medium;
        font-size: @aui-font-size-medium;
    }

    legend + .field-group {
        margin-top: 0;
        padding-top: 5px;
    }

    div.description {
        font-size: @aui-font-size-xsmall;
        line-height: unit((20 / @aui-font-size-xsmall));
        color: @aui-form-description-text-color;
        margin: 5px 0 0 0;

        &:first-child {
            margin-top: 0;
        }
    }

    legend,
    .field-group > label,
    .field-group > aui-label {
        float: left;
        margin-left: -145px;
        padding: 5px 0 0 0;
        position: relative;
        text-align: right;
        width: 130px;
        word-wrap: break-word;
    }
}

@import 'forms-radios-and-checkboxes';
