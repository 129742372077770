@import (reference) 'imports/mixins';

.clear {
    clear: both;
}

.hidden {
    display: none !important;
}

.assistive,
form.aui legend.assistive {
    #aui.visually-hidden();
}
