@import (reference) 'aui-theme/components/messages';
@import (reference) 'aui-theme/core/colors';

@import (reference) './mixins/dropdown2-trigger-chevron-icon';
@import (reference) './mixins/focus';
@import (reference) './mixins/icon-pseudoelement';
@import (reference) './mixins/media-queries';
@import (reference) './mixins/shadows';
@import (reference) './mixins/tab-indicator';
@import (reference) './mixins/typography';

#aui {
    .visually-hidden() {
        // this mixing is here only to reduce boilerplate (!important suffixes) - see the following call
        .assistive-declarations() {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
        // AUI-5343 - prevents unexpected overrides of declarations from .assistive-declarations mixin
        .assistive-declarations() !important;
    }

    .animation(@animation) {
        animation: @animation;
    }

    .border-radius(@radius) {
        border-radius: @radius;
    }

    .box-sizing(@sizing: border-box) {
        box-sizing: @sizing;
    }

    .prevent-text-selection() {
        user-select: none;
    }

    .gradient {
        .vertical(@start-color: #fff, @end-color: #f2f2f2, @start-percent: 0%, @end-percent: 100%) {
            background: @end-color;
            background: linear-gradient(to bottom, @start-color @start-percent, @end-color @end-percent);
        }
    }

    .rotate(@angle) {
        transform: rotate(@angle);
    }

    // Styles that text containers should set, in case they are embedded in weird flow content like
    // <h1>, <strong>, <em>, etc., so that inheritable styles don't bleed in unintentionally.
    // be mindful of the specificity of the rule(s) you mix this in to, as you may just as easily
    // prevent desirable inheritance, too!
    .text-reset() {
        color: @aui-text-color;
        font-size: @aui-font-size-medium;
        font-style: normal;
        font-variant: normal;
        font-weight: @aui-font-weight-normal;
        letter-spacing: @aui-font-letter-spacing;
        line-height: unit((20 / @aui-font-size-medium));
        text-align: left; // in case anybody used text-align for layout purposes
        white-space: normal;
    }

    .text-truncate() {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .transition(@transition) {
        // Using + is deliberate; it will comma-append the value to whatever value already exists.
        // See http://lesscss.org/features/#merge-feature-comma
        transition+: @transition;
    }

    .transition {
        .fadeIn(@length: 0.2s, @delay: 0s, @max-opacity: 1) {
            opacity: @max-opacity;
            transition: opacity @length;
            transition-delay: @delay;
            visibility: visible;
        }

        .fadeOut(@length: 0.2s, @delay: 0s) {
            opacity: 0;
            transition: opacity @length, visibility @length;
            transition-delay: @delay;
            visibility: hidden;
        }
    }

    .transform-rotate(@angle) {
        transform: rotate(@angle);
    }

    .height-calc(@calc) {
        height: ~"calc(@{calc})";
    }

    .placeholder(@color) {
        &::placeholder {
            color: @color;
            opacity: 1;
        }
        // Target AUI's JS equivalent of the CSS proposal for the `:placeholder-shown` pseudo-class.
        &.aui-placeholder-shown, &.placeholder-shown {
            color: @color;
        }
    }

    //
    // Responsive mixins
    //
    .responsive-small(@rules) {
        html.aui-responsive {
            @media screen and (max-width: 767px) {
                @rules();
            }
        }
    }
    .responsive-medium(@rules) {
        html.aui-responsive {
            @media screen and (max-width: 800px) {
                @rules();
            }
        }
    }

    .responsive-large(@rules) {
        html.aui-responsive {
            @media screen and (max-width: 1023px) {
                @rules();
            }
        }
    }
}
