.aui-checkbox-multiselect {
  display: inline-block;

  .aui-button {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.aui-checkbox-multiselect-dropdown {
  .hidden {
    display: none;
  }
  .aui-button-link {
    margin-left: 10px;
  }
}