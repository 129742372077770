@import (reference) './imports/global';

/**
 * AUI Page Header
 */

.aui-page-header-inner {
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.aui-page-header-image,
.aui-page-header-main,
.aui-page-header-actions {
    margin: 0;
    padding: 0;
    text-align: left;
}

.aui-page-header-image {
    flex-grow: 0; // collapse the cell to fit its content
    white-space: nowrap;
    align-self: flex-start;
}
.aui-page-header-main {
    flex-grow: 1;
}

.aui-page-header-image + .aui-page-header-main {
    padding-left: @aui-grid;
}

.aui-page-header-main > h1,
.aui-page-header-main > h2,
.aui-page-header-main > h3,
.aui-page-header-main > h4,
.aui-page-header-main > h5,
.aui-page-header-main > h6 {
    margin: 0;
}

.aui-page-header-actions {
    padding-left: (@aui-grid * 2);
    text-align: right;
}

.aui-page-header-actions > .aui-buttons {
    /* spaces out button groups when they wrap to 2 lines */
    margin-bottom: (@aui-grid / 2);
    margin-top: (@aui-grid / 2);
    white-space: nowrap;
}
