@import (reference) './imports/global';

.aui-banner {
    --aui-message-padding: 10px;
    --aui-message-gutter: 40px;
    --aui-message-isize: @aui-icon-size-small;
    --aui-focus: currentcolor;
    --aui-link-color: currentcolor;
    --aui-link-hover-color: currentcolor;
    --aui-link-active-color: currentcolor;
    border-radius: 0;
    box-shadow: none;
    margin-top: 0;

    #aui-message.icon-bar(var(--aui-message-ig), var(--aui-message-icolor));
    &::after {
        left: 15px;
    }

    padding: var(--aui-message-padding);
    position: relative;

    a:not(.aui-button),
    .aui-button-link {
        text-decoration: underline;
    }

    &.aui-banner-error, &.aui-banner-warning {
        padding-left: var(--aui-message-gutter);
    }

    &.aui-banner-error {
        --aui-message-ig: @aui-glyph-exclamation-circle;
        background-color: var(--aui-banner-error-bg-color);
        color: var(--aui-banner-error-text-color);
    }
    &.aui-banner-warning {
        --aui-message-ig: @aui-glyph-exclamation-triangle;
        background-color: var(--aui-banner-warning-bg-color);
        color: var(--aui-banner-warning-text-color);
    }
    &.aui-banner-announcement {
        background-color: var(--aui-banner-announcement-bg-color);
        color: var(--aui-banner-announcement-text-color);
    }
}
