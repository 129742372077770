@import (reference) './imports/global';
@import (reference) './imports/mixins/focus';

/*! AUI Button */

// Basic layout
.aui-button {
    #aui.focus-visible(#aui.with-focus-ring());
    #aui.box-sizing();
    #aui-buttons.aui-button-base();

    display: inline-block;
    height: @aui-button-height; // 30px - using ems so the fields increase in line with user specified font-sizes
    line-height: @aui-button-line-height;
    margin: 0;
    padding: @aui-button-padding-y @aui-button-padding-x;
    vertical-align: baseline;
    white-space: nowrap;
}

// Basic button type
.aui-button {
    #aui-buttons.aui-button-style(normal);
}

// Ensure base link styles do not take precedence over button styles
a.aui-button,
.aui-button:link,
.aui-button:visited {
    #aui-buttons.aui-button-style();
    text-decoration: none;
}

// Subtle button type
.aui-button-subtle {
    #aui-buttons.aui-subtle-button-style(normal);
}

// Light button type
.aui-button-light {
    --aui-btn-bg: var(--aui-button-light-bg-color);
}

//
// Button pseudo-state styles.
// NOTE:
//   Because of the cascade, subtle & light button types receive these styles, too.
//   This is deliberate! ;)
//
.aui-button {
    &:focus,
    &:hover {
        #aui-buttons.aui-button-style(hover);
    }

    &:active {
        #aui-buttons.aui-button-style(active);
    }

    &.active:not(.aui-button-link) {
        #aui-buttons.aui-button-style(selected);
    }
}

// Primary button type
.aui-button-primary {
    #aui-buttons.aui-primary-button-style(normal);

    &:hover,
    &:focus {
        #aui-buttons.aui-primary-button-style(hover);
    }

    &:active {
        #aui-buttons.aui-primary-button-style(active);
    }

    &.active {
        #aui-buttons.aui-button-style(selected);
    }
}

// Warning button type
.aui-button-warning {
    #aui-buttons.aui-warning-button-style(normal);

    &:hover,
    &:focus {
        #aui-buttons.aui-warning-button-style(hover);
    }

    &:active {
        #aui-buttons.aui-warning-button-style(active);
    }

    &.active {
        #aui-buttons.aui-button-style(selected);
    }
}

// Danger button type
.aui-button-danger {
    #aui-buttons.aui-danger-button-style(normal);

    &:hover,
    &:focus {
        #aui-buttons.aui-danger-button-style(hover);
    }

    &:active {
        #aui-buttons.aui-danger-button-style(active);
    }

    &.active {
        #aui-buttons.aui-button-style(selected);
    }
}

// Link + Text button types
.aui-button-link,
.aui-button-text {
    #aui-buttons.aui-link-button-style(normal);

    &:focus,
    &:hover {
        #aui-buttons.aui-link-button-style(hover);
    }

    &:active {
        #aui-buttons.aui-link-button-style(active);
    }
}

.aui-button {
    // Selected button state - NOT supported for free-standing primary buttons
    // ARIA usage from http://www.paciellogroup.com/blog/misc/ARIA/togglebutton.html
    &[aria-pressed="true"] {
        #aui-buttons.aui-button-style(selected);
    }

    &[aria-pressed="true"]:active {
        #aui-buttons.aui-button-style(active);
    }

    // Disabled button states
    // NOTE: these must be placed after all button types + states
    // in order to avoid excessive selector weight.
    &[disabled], &[aria-disabled="true"] {
        & {
            #aui-buttons.aui-button-style(disabled);
        }

        &.aui-button-subtle {
            --aui-btn-bg: var(--aui-button-subtle-bg-color);
        }

        &.aui-button-primary {
            #aui-buttons.aui-primary-button-style(disabled);
        }

        &.aui-button-link,
        &.aui-button-text {
            #aui-buttons.aui-link-button-style(disabled);
        }
    }
}

// Button variants
// -------------
// Where 'types' are stylistic, 'variants' affect the button layout.

// Link buttons
.aui-button.aui-button-link {
    padding-left: 0;
    padding-right: 0;
}

// Text buttons
.aui-button.aui-button-text {
    border: none;
    font-size: inherit;
    height: inherit;
    line-height: normal;
    padding: 0;
}

// Split buttons
.aui-button.aui-button-split-main {
    + .aui-button-split-more {
        margin-left: @aui-grouped-button-spacing;
    }
}

// Icon buttons - when a button label is present next to an aui-icon (eg sidebar config)
.aui-button {
    > .aui-icon {
        + .aui-button-label {
            margin-left: (@aui-grid / 2)-1;
        }
    }
}

// Compact buttons
.aui-button.aui-button-compact {
    font-size: @aui-font-size-small;
    height: unit((26px / @aui-font-size-small), em); // 26px with 12px font-size
    padding: (@aui-button-padding-y / 2) (@aui-button-padding-x * .8);
    line-height: unit((20 / @aui-font-size-small));
}

//
// Button groupings
// -------------
.aui-button ~ .aui-button {
    margin-left: @aui-grid;
}

// aui-buttons groups buttons together into one button lozenge.
.aui-buttons {
    display: inline-flex;
}

.aui-buttons::after {
    clear: both;
    content: "";
    display: table;
}

.aui-buttons .aui-button {
    border-radius: 0;
    margin: 0;
}

.aui-buttons .aui-button:first-child {
    border-top-left-radius: @aui-button-border-radius;
    border-bottom-left-radius: @aui-button-border-radius;
}

.aui-buttons .aui-button:last-child {
    border-top-right-radius: @aui-button-border-radius;
    border-bottom-right-radius: @aui-button-border-radius;
}

.aui-buttons + .aui-buttons {
    margin-left: @aui-grid;
}

// Grouped Primary Buttons
.aui-buttons .aui-button ~ .aui-button {
    border-left-width: 0;
    margin-left: @aui-grouped-button-spacing;
}

.aui-buttons .aui-button {
    &:hover,
    &:focus,
    &:active {
        position: relative;
    }

    &:focus {
        z-index: 1;
    }
}

// Cross-component integration
// ----------------------------------------------------------------------
// Icons Integration
// ---------------------

.aui-button.aui-button-compact .aui-icon {
    margin-top: -1px; // icon is 1px off, adjust it so it is centered
    vertical-align: middle;
}

// Button link - icon and text
.aui-button.aui-button-link.aui-button-link-icon-text {
    &:hover,
    &:focus {
        text-underline-position: under;

        .aui-icon {
            // Align icon with underline from parent (button)
            text-underline-position: from-font;
            // Safari works without above (but other browsers need it)
            // It lowers underline on icon, but not on text (Safari)
            text-underline-offset: 1px;
        }
    }
}

// Icon Colours
// ----------
.aui-button .aui-icon {
    color: inherit;
}

// Busy buttons
.aui-button[busy] {
    position: relative;
    // make the text 'disappear' while the spinner is visible
    color: transparent !important;
    // make the text unselectable while the spinner is visible,
    // so it doesn't accidentally become visible again in a ctrl+a scenario
    user-select: none;

    aui-spinner {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        color: var(--aui-btn-text);
    }
}
