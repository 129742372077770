@import (reference) './imports/global';

#aui-flag-container {
    @gutter: @aui-grid * 3;
    position: fixed;
    pointer-events: none;
    top: @gutter + 55px;  // height of header plus some fudge.
    width: 100%;
    z-index: @aui-z-flag-container;

    @media (min-width: 600px) {
        right: @gutter;
        width: auto;
    }
}

.aui-flag {
    display: block;
    position: relative;
    opacity: 0;
    max-height: 300px;
    margin-inline: @aui-space-200;

    #aui.transition(opacity @aui-flag-fade-in-time);
    #aui.transition(top @aui-flag-slide-in-time);

    &:not([open]) {
        left: 300px;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        top: 0;

        #aui.transition(max-height @aui-flag-stack-shrink-time @aui-flag-stack-shrink-delay);
        #aui.transition(margin-bottom @aui-flag-stack-shrink-time @aui-flag-stack-shrink-delay);
        #aui.transition(opacity @aui-flag-fade-out-time);
        #aui.transition(left @aui-flag-slide-out-time);
    }

    &[open] {
        margin-bottom: @aui-flag-gap-mobile;
        opacity: 1;
        top: 0;
        left: 0;

        @media (min-width: 600px) {
            margin-bottom: @aui-flag-gap-desktop;
        }
    }

    @media (min-width: 600px) {
        width: 400px;
    }

    .aui-message {
        background-color: var(--aui-flag-bg-color);
        color: inherit;
        border-width: 0;
        pointer-events: auto;

        --aui-message-icolor: var(--aui-flag-info-color);
        --aui-message-padding: @aui-flag-padding;

        #aui.shadow.z500();

        &.aui-message-warning {
            --aui-message-icolor: var(--aui-flag-warning-color);
        }

        &.aui-message-error {
            --aui-message-icolor: var(--aui-flag-error-color);
        }

        &.aui-message-success {
            --aui-message-icolor: var(--aui-flag-success-color);
        }
    }
}