@import (reference) './media-queries';

#aui {
    .shadow {
        // For use on cards and other things that sit flush on top of other layers.
        .z100() {
            box-shadow:
                0 1px 1px var(--aui-shadow1),
                0 0 1px 0 var(--aui-shadow2);
        }

        // For inline dialogs
        .z200() {
            .z-base(0 4px 8px -2px var(--aui-shadow2));
            #aui-themes.when-design-tokens({
                box-shadow: var(--ds-shadow-overlay, 0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31))
            });
        }

        // For modal dialogs
        .z300() {
            .z-base(0 8px 16px -4px var(--aui-shadow2));
            #aui-themes.when-design-tokens({
                box-shadow: var(--ds-shadow-overlay, 0 0 0 1px rgba(9, 30, 66, 0.08), 0 2px 1px rgba(9, 30, 66, 0.08), 0 0 20px -6px rgba(9, 30, 66, 0.31));
            });
        }

        // For flags and notifications
        .z500() {
            .z-base(0 20px 32px -8px var(--aui-shadow2));
            #aui-themes.when-design-tokens({
                box-shadow: var(--ds-shadow-overlay, 0px 8px 12px #091e423f, 0px 0px 1px #091e424f);
            });
        }

        // Internal use only
        .z-base(@shadow) {
            box-shadow: @shadow, 0 0 1px var(--aui-shadow2);
            // Improve legibility of the element's "border" in Legacy Edge.
            .legacy-edge({
                box-shadow: @shadow,
                    0 0 1px var(--aui-shadow1),
                    1px 0 1px var(--aui-shadow1),
                    -1px 0 1px var(--aui-shadow1),
                    0 -1px 1px var(--aui-shadow1);
            });
        }
    }
}
