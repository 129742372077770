@import (reference) '../theme';

@aui-message-border-radius: @aui-border-radius-small;
@aui-message-line-height: 20px;

// messages / flags
#aui-message {
    .icon-bar(@content, @color) {
        //The actual icon
        &::after {
            #aui.icon-pseudoelement(@aui-icon-font-family);
            content: @content;
            color: @color;
            font-size: var(--aui-message-isize);
            left: var(--aui-message-padding);
            line-height: inherit;
            position: absolute;
            top: var(--aui-message-padding);
        }

        // For the backward compatible changes
        &::before {
            display: none;
        }
    }
}
