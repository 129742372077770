.responsive-max-width(@maxWidth; @rules) {
    @media all and (max-width: @maxWidth) {
        @rules();
    }
}

.responsive-max-height(@maxHeight; @rules) {
    @media all and (max-height: @maxHeight) {
        @rules();
    }
}

.legacy-edge(@rules) {
    @supports (-ms-accelerator:true) or (-ms-ime-align:auto) {
        @rules();
    }
}

.firefox(@rules) {
    @media all and (min--moz-device-pixel-ratio:0) {
        @rules();
    }
}
